"use strict";

// 韩语
export default {
	"backGame": "돌아 가기",
	"title": "선택사항 ",
	"issues": [
		{
			"title": "세이브 관련",
			"list": [
				{
					"issueTitle": "게임 진행 상황은 어떻게 저장하나요?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Game Center 또는 Play Games 계정을 사용해서 Punball에 입장하실 수 있습니다.",
						"<b>손님으로 로그인</b>： 첫 게임플레이이고 Game Center (iOS) 또는 Play Games (Android) 계정으로 로그인하시지 않은 경우는 게임에 손님으로 입장하시게 됩니다. 손님 계정의 세이브 데이터는 당신의 휴대폰에 연계되므로, 게임을 삭제 후 다시 다운로드해도 세이브 내용이 없어지지 않습니다. 하지만 손님 계정의 세이브 데이터는 다른 기기로 이전되지 않습니다. 휴대폰의 공장 초기화나, 플래싱, 또는 탈옥 등의 행위를 하시면 세이브 데이터를 잃을 수 있다는 점에 유의하세요. 손님 계정의 세이브 데이터가 소멸되는 것을 방지하려면 Game Center (iOS) 또는 Play Games (Android) 계정으로 로그인하실 것을 권장합니다.",
						"<b>계정 로그인</b>：첫 게임플레이 전에 Game Center (iOS) 또는 Play Games (Android) 계정으로 로그인하시면, 디폴트 계정으로 게임에 입장하실 수 있습니다. 게임 진행 상황은 내 Game Center (iOS) 또는 Play Games (Android) 계정에 자동으로 저장됩니다.。"
					],
				}, 
				{
					"issueTitle": "내 세이브 내용을 다른 기기로 옮기고 싶어요.",
					"issueAnswerType": 1,
					"issueAnswer": [
						"세이브 데이터는 Game Center (iOS) 또는 Play Games (Android) 계정을 통해 다른 기기로 전송 가능합니다. 단, 현재는 세이브 데이터를 같은 OS를 사용하는 기기로만 전송할 수 있습니다. 예를 들어서 iOS는 iOS로, Android는 Android로만 전송됩니다.",
						"방식: 이전 기기에서 Game Center (iOS) 또는 Play Games (Android) 계정에 로그인돼 있는지 확인하세요. 새 기기에서 같은 Game Center (iOS) 또는 Play Games (Android) 계정으로 로그인하신 후에 게임을 다운로드하세요. 게임을 실행하면 계정을 탐지해서 세이브 데이터를 복구합니다. (Android 사용자는 Play Games에서 게임을 실행하셔야 합니다.)"
					]
				},
				{
					"issueTitle": "새 게임을 시작하고 싶어요.",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>내 현재 세이브를 유지하고 싶어요</b>：Game Center (iOS) 또는 Play Games (Android) 계정에 로그인돼 있는지 확인하세요. 로그인돼 있다면, 계정에 연계된 세이브 데이터가 저희 서버에 자동으로 저장됩니다. 이제 본인 계정에서 로그아웃하고, 게임을 삭제 후 다시 다운로드하세요. 그런 다음, (Punball 입장에 한 번도 쓰인 적 없는) 새 계정으로 로그인해서 새 세이브를 생성하세요. 구 계정으로 플레이하려면, 이전 Game Center (iOS) 또는 Play Games (Android) 계정으로 전환하신 다음, 게임을 다시 다운로드해서 실행하세요. 시스템에서 이전 사용 내역을 탐지해서 로드합니다.",
						"<b>내 세이브 데이터가 더 이상 필요 없어요</b>：게임 설정에서 \"문의하기\"로 이동하셔서 세이브를 삭제하고 새 게임을 시작하고자 한다고 문의해 주세요. 메시지 수령 후, 1~2 영업일 이내에 고객님의 세이브를 삭제합니다. 삭제된 세이브 데이터는 복구될 수 없으므로, 잘 생각해서 결정하세요. 플레이를 많이 하실수록 더 잘 하시게 될 것이므로, 세이브 데이터를 삭제하는 것은 권장하지 않습니다. 게임을 새로 시작하는 것은 좋은 생각이 아닙니다."
					]
				},
				{
					"issueTitle": "내 게임 진행 상황을 잃어버렸어요.",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"저희는 매일 부정 행위자의 계정을 차단하고 해당 세이브를 삭제합니다. 고객님의 세이브 데이터가 삭제됐다면 정말 죄송합니다. 모든 사람이 정석적인 채널을 통해 게임을 다운로드하고 부정 행위를 일체 하지 않는 것으로 여러분 모두에게 좋은 게임 체험을 제공해 드리고자 합니다.",
						"<b>부정 행위를 하지 않았는데도 진행 상황이 없어졌어요:</b>：",
						"<b>손님 계정</b>：다른 세이브 데이터에 연계된 Game Center (iOS) 또는 Play Games (Android) 계정에 로그인하셨을 수도 있습니다. 본인의 손님 계정 저장 데이터를 복구하려면, 현재 계정에서 로그아웃하고 게임을 삭제 후 다시 다운로드하세요. (세이브 데이터 분실을 방지하기 위해 새 계정으로 로그인하실 것을 권장합니다.)",
						"<b>내 계정에 로그인했는데도 세이브가 없어졌어요</b>：계정을 전환하신 것은 아닌지 확인하세요. 다른 Game Center (iOS) 또는 Play Games (Android) 계정으로 전환하신 경우, 휴대폰 설정으로 이동해서 이전 계정으로 다시 전환하세요.",
						"<b>본인 계정 복구가 안 되는 경우</b>：다음 사항을 기입해서 언제든 저희에게 문의해 주세요:",
						"1. 본인의 이전 계정의 사용자 ID",
						"2. 본인의 현재 계정",
						"3. 본인의 이메일 주소",
						"4. 계정이 없어지기 전에 무엇을 하고 계셨는지.",
						"이전 계정에서 하신 인앱 구매가 복구되지 않는 경우, 고객님의 이전 계정에서 구매하신 모든 아이템을 전송해 드릴 수 있습니다. 여기에는 다음 정보를 제공해 주셔야 합니다:",
						"1. 본인의 새로운 계정의 사용자 ID",
						"2. 구매한 아이템 및 수량",
						"3. 거래하신 내역의 모든 스크린샷 또는 오더 ID (저희는 Apple 또는 Google 청구서만 받습니다. 지불하신 카드 또는 지불 관련 문자 등 다른 종류의 지불 정보는 보내지 마십시오.)상기 정보를 저희 이메일로 보내 주세요: punball@habby.com. 인증이 되면, 구매하신 모든 아이템을 고객님의 새 계정으로 반환해 드립니다."
					]
				},
				{
					"issueTitle": "게임을 여러 기기에서도 플레이할 수 있나요?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>가능하지만, 추천하지는 않습니다</b>：복수의 기기에 내 게임 진행을 동기화하려면, 다른 기기에서 같은 Game Center (iOS) 또는 Google Play (Android) 계정으로 로그인만 하시면 자동으로 진행됩니다. 이런 방식은 권장하지 않습니다. 여러 기기에서 같은 세이브 데이터를 공유하는 경우, 만약 기기 A에서 무료 보물상자를 연 뒤에 다시 기기 B에서 같은 보물상자를 열게 되면, 해당 보물상자를 기기 A에서 이미 열었기 때문에 보석 수가 차감됩니다. 사고로 보석을 잃는 것을 방지하려면, 게임을 여러 기기에서 동시에 진행하지 않으실 것을 추천합니다."
					]
				}
			]
		}, 
		{
			"title": "구매 관련",
			"list": [
				{
					"issueTitle": "내가 구매한 아이템을 왜 받지 못하나요?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"네트워크 연결을 확인하세요. 게임을 종료하고 나중에 다시 시작하세요.",
						"확실히 아이템을 받지 못하셨다면, 다음 정보를 포함해서 저희에게 문의해 주세요:",
						"1.<b>본인의 플레이어 ID</b> (게임 설정 내에서)",
						"2.<b>구매한 아이템 및 수량</b>",
						"3.<b>거래하신 내역의 스크린샷 또는 오더 ID </b>(저희는 Apple 또는 Google 청구서만 받습니다. 지불하신 카드 또는 지불 관련 문자 등 다른 종류의 지불 정보는 보내지 마십시오.)상기 정보를 저희 이메일로 보내 주세요: punball@habby.com.고객님의 정보를 받고 확인이 되면 패키지를 보내 드리겠습니다. 2~3 영업일 정도가 소요됩니다. 조금만 기다려 주시기 바라며, 같은 메시지를 반복해서 보내지 마십시오. 고객님의 양해에 감사드립니다."
					]
				},
				{
					"issueTitle": "보석과 코인 개수 오류",
					"issueAnswerType": 1,
					"issueAnswer": [
						"코인 또는 보석 수에 오류가 있다면, 먼저 앱을 재시작하신 후에 연결을 확인하세요. 다른 기기에서 게임에 로그인했는지, 그리고 그 기기에서 코인 또는 보석을 사용했는지 확인하세요.",
						"코인 또는 보석 개수에 확실히 오류가 있다면, 다음 정보를 포함해서 저희에게 문의해 주세요:",
						"1. 고객님의 플레이어 ID 및 분실한 코인 또는 보석의 개수.",
						"2. 스크린샷 또는 화면 동영상이 있으면 punball@habby.com으로 보내 주세요. 더 빨리 문제를 확인해서 보상을 보내 드릴 수 있습니다.",
						"3. 아이템 분실 직전에 어떤 행동을 하셨는지 알려 주세요 (재인스톨이나 업데이트, 또는 다른 기기에서 게임을 실행).",
					]
				},
				{
					"issueTitle": "환불을 받고 싶어요.",
					"issueAnswerType": 1,
					"issueAnswer": [
						"저희 환불 정책은 다음과 같습니다. 다음 조건을 충족하는 경우에만 환불이 가능합니다:",
						"1. 구매하신 보석, 코인, 기타 인게임 아이템이 고객님의 계정에 들어 있고 한 번도 사용되지 않은 경우만 환불 요청을 받을 수 있습니다.",
						"2. 구매한 아이템에 대해 이전에 환불을 받은 기록이 있는 경우, 다른 구매 내역에 대해서는 환불해 드리지 않습니다.",
						"3. 구매 시점으로부터 7일 이내에 환불 요청을 하신 경우에만 환불이 가능합니다. 7일 이후에 제출된 환불 요청은 거부됩니다.",
						"4. 저희 환불 조건이 충족되는 경우, 거래 내역의 스크린샷 또는 오더 ID, 그리고 게임 내 본인의 플레이어 ID를 제출해 주시기 바랍니다. 저희 이메일 주소는 다음과 같습니다: punball@habby.com. 이메일로 환불 요청을 받은 경우, 인증을 통해 확인이 되면 환불 처리를 해드립니다. 2~3 영업일 정도가 소요됩니다. 조금만 기다려 주시기 바라며, 같은 메시지를 반복해서 보내지 마십시오. 고객님의 양해에 감사드립니다.주: 고객님의 계정 또는 기기를 타인이 사용한 경우, 또는 타인에게 본인 계정에서 구매를 허용하신 경우, 고객님의 계정에서 이루어진 구매에 대해 저희는 책임을 지지 않습니다. 이런 경우, 환불은 불가합니다. 감사합니다."
					]
				},
				{
					"issueTitle": " 실수로 구매하는 일을 방지하려면 어떻게 하나요?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Punball는 전 세계 어디서나 무료 다운로드가 가능한 게임이지만, 패키지 및 보석은 인게임 상점에서 구입하실 수 있습니다. 실수로 구매하는 일을 방지하려면, 기기의 설정을 변경하세요.iOS 기기:",
						"1. 설정 > 일반 > 차단으로 이동합니다.",
						"2. 차단 활성을 선택합니다.",
						"3. 비밀번호를 입력하세요. 기기의 비밀번호와 다른 비밀번호를 설정하실 것을 권장합니다.",
						"4. 차단 기능을 켜시면, 앱에 허용된 작업 리스트가 표시됩니다. 인앱 구매를 방지하려면, 해당 옵션을 끄십시오.",
						"인앱 구매를 완전히 방지하고 싶지 않은 경우, 해당 앱의 비밀번호 설정을 변경하세요. 차단을 끄신 경우, 설정 > App Store > 비밀번호 설정으로 이동하세요.",
						"iOS 12.3 이상:",
						"1. 설정 > Screen Time > 콘텐트 & 개인 정보 보호로 이동합니다.",
						"2. 콘텐트 & 개인 정보 보호를 켭니다.",
						"3. iTunes 및 App Store 구매를 탭합니다.",
						"4. 항상 확인을 탭하거나, 인앱 구매 > 비허용을 탭합니다.",
						"Android 기기:",
						"1. Play Store를 엽니다.",
						"2. 설정으로 이동합니다.",
						"3. 구매 시 승인 확인을 탭합니다.",
						"4. 절대 사용 안 함 이외의 옵션을 선택하세요.",
						"이제 Google Play를 통한 모든 인앱 구매 시 승인을 해야 합니다.",
						"주: “30분마다”를 선택하신 경우, 비밀번호를 입력해서 거래를 완료할 때마다, 이후 30분 이내에 진행되는 모든 구매에 비밀번호 요구를 받지 않습니다."
					]
				}
			]
		},
		{
			"title": " 네트워크 관련",
			"list": [
				{
					"issueTitle": "에너지 오류",
					"issueAnswerType": 1,
					"issueAnswer": [
						"다음 사항을 확인하세요:",
						"1. 네트워크를 확인하고 기기가 접속돼 있는지 확인하세요.",
						"2. 다른 기기에서 게임을 실행했는지, 그리고 그 기기에서 에너지를 사용했는지 확인하세요.",
						"3. 기기의 시간대를 변경했는지 확인하세요. 시간대 변경으로 인해 발생한 에너지 문제는 저희가 도와 드릴 수 없습니다.",
						"문제가 지속되면 다음 사항에 대해 문의해 주세요:",
						"1. 실제 에너지와 에너지 변동의 구체적인 수치.",
						"2. 정확히 어느 시점에서 에너지 변동이 발생했습니까?",
						"3. 본인의 플레이어 ID.",
						"문제가 확인되면 소정의 보상을 드립니다. 2~3 영업일 정도가 소요됩니다. 조금만 기다려 주시기 바라며, 같은 메시지를 반복해서 보내지 마십시오. 고객님의 양해에 감사드립니다."
					]
				},
				{
					"issueTitle": "광고 관련 문제",
					"issueAnswerType": 1,
					"issueAnswer": [
						"광고를 시청할 수 없음:",
						"다음 사항을 확인하세요:",
						"1. 휴대폰의 네트워크가 정상인지 확인하세요. 접속을 4G/wifi로 전환해 보세요.",
						"2. 광고 로드에는 시간이 걸리므로, 게임을 시작하자마자 바로 광고를 탭하지 마십시오. 네트워크 접속 상태가 좋아지도록 잠시 동안 게임을 플레이하신 후에, 다시 광고를 시청하세요.",
						"이 방법으로 문제가 해결되지 않으면, 고객님의 플레이어 ID와 광고 로드가 안되는 부분에 대해서 저희에게 문의해 주십시오.",
						"부적절한 앱:",
						"게임 내에서 부적절한 (폭력적이거나 도박 관련 등) 광고가 있다면, 다음 정보를 포함해서 punball@habby.com에게 이메일을 보내 주세요:",
						"1. 광고 내의 부적절한 콘텐트 또는 동영상의 스크린샷을 첨부해 주세요.",
						"2. 광고에서 리디렉트한 스폰서 페이지의 스크린샷 또는 화면 녹화.",
						"3. 본인의 플레이어 ID. 확인이 되면, 보상으로 보석을 드립니다."
					]
				}
			]
		},
		{
			title: "문의하기"
		}
	],
	"feedSubmit": {
		"contactUS": "고객 문의",
		"contentPlaceholder": "문제에 대해 기재해 주세요",
		"emailPlaceholder": "연락드릴 수 있도록 이메일을 남겨 주세요:",
		"submit": "제출",
		"success": "메시지가 성공적으로 전송되었습니다. 의견을 제공해 주셔서 감사합니다.",
		"returnToGame": "게임으로 돌아가기",
		"view": "내용보기",
		"isAgree": "개인정보 수집 및 이용동의",
		"isAgeMatched": "본인은 만 14세 이상입니다. (필수)",
		"tip": "* 설정에서 개인정보 정책 확인 가능",
	}
}