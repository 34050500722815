"use strict";

export default {
	"backGame": "返回游戲",
	"title": "選擇問題",
	"issues": [
		{
			"title": "存檔問題",
			"list": [
				{
					"issueTitle": "我們如何保存遊戲進度？",
					"issueAnswerType": 1,
					"issueAnswer": [
						"《Punball》使用 Game Center 和 Play遊戲 來登陸游戲。",
						"<b>遊客登陸</b>：如果您是首次登錄且未登錄GameCenter（iOS）或Play遊戲（Android），您將以遊客狀態進入遊戲。遊客身份下，進度根據當前手機進行保存，刪除重新下載進度也不會丟失，但是遊客身份無法將進度轉移至其他設備。請注意：當手機進行恢復出廠、刷機、越獄等操作時，遊客進度可能會丟失。為了避免您的遊客存檔意外丟失，所以我們建議您登陸賬號。",
						"<b>賬號登陸</b>：如果您的首次進入遊戲之前登錄了GameCenter（iOS）或Play遊戲（Android），默認為賬號登陸，您的遊戲記錄將自動保存至您的GameCenter（iOS）或Play遊戲（Android）。"
					],
				}, 
				{
					"issueTitle": "我想將存檔轉移至其他設備？",
					"issueAnswerType": 1,
					"issueAnswer": [
						"我們可以通過GameCenter（iOS）或Play遊戲（Android）將游戲賬號轉移至其他設備，但目前只能在相同平台下進行轉移。例如：IOS轉移至IOS，安卓轉移至安卓。",
						"操作方法：確保舊設備已經登陸了GameCenter（iOS）或Play遊戲（Android）賬號，然後在新設備上登陸相同的GameCenter（iOS）或Play遊戲（Android）賬號並下載遊戲。然後進入遊戲後會檢測到已登陸的賬號並恢復遊戲進度。 （安卓需要從Play 遊戲啟動登陸游戲）"
					]
				},
				{
					"issueTitle": "我想重新開始玩遊戲",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>我還想保留我當前的進度</b>：您必須確保已經登陸GameCenter（iOS）或Play遊戲（Android）賬號，登陸過賬號的存檔會自動保存至我們的服務器。此時，你可以退出你的賬號，然後刪除遊戲重新下載，並登陸一個新的賬號（從未登陸過Punball的賬號），此時將會為你創建一個新的存檔。如果你想玩回老賬號，切換回原來的GameCenter（iOS）或Play遊戲（Android）賬號，再次重新下載，再次進入遊戲之後，系統會識別到您之前的存檔並加載。",
						"<b>我不想要我的存檔了</b>：可以通過遊戲設置中的聯繫我們告訴我們您需要刪除存檔重新開始。我們的工作人員收到後會在1至2個工作日內為你清除存檔。清除存檔無法重新開始，請慎重考慮。並且我們不建議您清除存檔，因為您通過遊戲時間的積累會越來越強，重新開始並不是一個好的想法。"
					]
				},
				{
					"issueTitle": "我丟失了我的進度",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"我們每日會對使用了作弊手段進行遊戲的玩家進行封號刪檔處理。如果您被刪檔了，我們感到非常抱歉。希望大家使用正規渠道下載遊戲，拒絕任何作弊行為，以免影響你和他人的遊戲體驗。",
						"<b>我沒有任何作弊行為，但丟失了遊戲進度：</b>：",
						"<b>遊客身份</b>：您可能登陸了一個已經被其他存檔佔用的GameCenter（iOS）或Play遊戲（Android）賬號。此時退出賬號，刪除重新下載，您的遊客存檔將找回。 （我們還是建議您登陸一個新的賬號，確保遊戲存檔不再丟失）",
						"<b>已登陸賬號，但記錄丟失</b>：先確認您是否切換過賬號，如果切換了GameCenter（iOS）或Play遊戲（Android）賬號，請在手機設置中切換回來。",
						"<b>如果您還是無法找回自己的賬號</b>：請隨時與我們聯繫，並且告訴我們：",
						"1.您舊賬號的用戶ID",
						"2.您當前的賬號",
						"3.您的郵箱",
						"4.您丟失賬號之前都經歷了什麼？",
						"如果您有充值過，並且無法找回，我們可以將您在舊賬戶的所有充值補償至您的新賬戶，我們需要您提供以下信息：",
						"1.您新賬戶的用戶ID",
						"2.你購買了什麼物品，數量",
						"3.所有的交易記錄截圖或訂單號ID（我們只接受Apple或Google賬單，請勿提供您支付卡付款信息或付款短信等其他付款信息）您可將以上信息發送到我們的郵箱punball@habby.com聯繫我們，我們會在審核成後後將您充值過的所有物品補償至您的新賬戶。"
					]
				},
				{
					"issueTitle": "我可以在多個設備上玩遊戲嗎？",
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>可以，但我們不建議這麼做</b>：您只需在多個設備登錄同一個GameCenter（iOS）或GooglePlay（Android）賬號，即可共享遊戲進度。但我們不建議這麼做，因為在同一個存檔下：假設在設備A使用了免費開寶箱的次數之後，在設備B上再次開啟免費寶箱，實際上會扣除鑽石，因為你在A設備已經開過一次了。為了避免出現寶石被意外扣除的情況，我們建議您不要在多個設備上同時玩遊戲。"
					]
				}
			]
		}, 
		{
			"title": "充值問題",
			"list": [
				{
					"issueTitle": "我充值了，但為什麼沒有收到物品？",
					"issueAnswerType": 1,
					"issueAnswer": [
						"請檢查您的網絡鏈接，並嘗試退出遊戲並稍後重新進入。",
						"如果確定沒有收到，請您聯繫我們，提供以下信息：",
						"1.<b>你的玩家ID</b>（在遊戲設置頁中查看）",
						"2.<b>你購買了什麼物品，數量</b>",
						"3.<b>交易記錄截圖或訂單號ID</b>（我們只接受Apple或Google賬單，請勿提供您支付卡付款信息或付款短信等其他付款信息）您可將以上信息發送到我們的郵箱punball@habby.com聯繫我們.我們在收到您發送的信息並核實之後會為您補發禮包，大概需要2至3工作日，請根據實際時間耐心等待，請不要多次給我們發送相同信息。感謝您的配合。"
					]
				},
				{
					"issueTitle": "我的鑽石和金幣數量發生異常？",
					"issueAnswerType": 1,
					"issueAnswer": [
						"如果您發現您的金幣，鑽石數量異常，請您先嘗試重啟客戶端並確認聯網。確認您的賬戶是否登陸了多設備，是否在其他設備上消耗掉了。",
						"如果您確認金幣，鑽石數量異常，請聯繫我們並提供以下信息：",
						"1.您的玩家ID，您失去的金幣或鑽石數量。",
						"2.如果您有截圖或者屏幕錄製，請發送郵件至punball@habby.com聯繫我們，這樣能更快幫助我們定位問題和對您進行補償。",
						"3.聯繫我們告知您在丟失物品之前都做了些什麼（重新安裝、更新、或者登陸新設備）",
					]
				},
				{
					"issueTitle": "我想退款",
					"issueAnswerType": 1,
					"issueAnswer": [
						"以下是我們遊戲處理退款的政策。只有滿足下列條件才能辦理退款：",
						"1.購買後，只有當您購買的鑽石、金幣或其他遊戲中的物品仍在賬戶中，並且沒有任何消耗的時候，我們才接受退款。",
						"2.如果你曾經購買了一件商品並退款了，我們將不再為您的購買進行退款。",
						"3.任何購買將只在購買後的7天內提出要求將有可能被退款。超過7天之後，退款將不被接受。",
						"4.如果您符合我們的退款條件，請聯繫我們並提供您的交易記錄截圖或訂單號ID和您遊戲內的玩家ID，我們的郵箱是：punball@habby.com我們在收到您發送的郵件之後會進行審核，審核成功後我們會為您辦理退款，大概需要2至3工作日，請根據實際時間耐心等待，請不要多次給我們發送相同信息。感謝您的配合。請注意：如果您的帳戶或設備被他人使用，或如果您允許他人為您的賬戶進行購買，我們對您賬戶的購買行為沒有任何責任，此時我們將不處理您的退款。謝謝各位。"
					]
				},
				{
					"issueTitle": "如何避免不小心意外購買？",
					"issueAnswerType": 1,
					"issueAnswer": [
						"《Punball》是一款全球免費下載遊戲，但遊戲內商城裡的禮包和鑽石可付費購買。如果您需要避免意外購買，請調整您的設備設置。IOS設備：",
						"1.前往手機設置->通用->訪問限制",
						"2.選擇啟動訪問限制",
						"3.輸入訪問限制選項密碼並確認，我們建議您設置與手機解鎖密碼不同的設備密碼",
						"4.啟用後，您會看到您的設備允許的應用程序的行為清單。若您要限制應用程序內購買，請關閉對應選項。",
						"若您不要完全限制應用程序內購買，可調整各應用程序的密碼設定。確認停用訪問限制後，前往設置->App Store -> 密碼設置",
						"IOS 12.3及以上",
						"前往手機設置->屏幕使用時間->內容和隱私訪問限制",
						"1.選擇啟用內容和隱私訪問限制",
						"2.APP內購買項目選擇不允許或始終需要密碼",
						"Android設備",
						"1.啟動 Play Store",
						"2.找到 設置",
						"3.點擊 購買時驗證身份 按鈕",
						"4.選擇除“一律不”之外的其他選項",
						"此時，通過Google Play進行應用程序內購買時，需要驗證。",
						"請注意：如果選擇“每30分鐘”，在輸入密碼後交易後的30分鐘內，任何購買都不需要輸入密碼。"
					]
				}
			]
		},
		{
			"title": "網絡問題",
			"list": [
				{
					"issueTitle": "體力出現異常",
					"issueAnswerType": 1,
					"issueAnswer": [
						"請確認以下問題：",
						"1.請確認您的網絡，是否聯網。",
						"2.請確認您是否登陸了多台設備，並在其他設備上消耗了體力。",
						"3.請確認您是否調整過本地設備時間，調整時間出現的體力問題我們將不予處理。",
						"如果您還是異常，請聯繫我們，並告訴我們：",
						"1.您和體力異常波動的具體數值？",
						"2.您在具體什麼時間段出現過體力異常？",
						"3.您的玩家ID",
						"我們會對您的問題核實後進行一定補償，大概需要2至3工作日，請根據實際時間耐心等待，請不要多次給我們發送相同信息。感謝您的配合。"
					]
				},
				{
					"issueTitle": "廣告出現問題？",
					"issueAnswerType": 1,
					"issueAnswer": [
						"無法觀看廣告：",
						"請確認以下問題：",
						"1.手機網絡情況正常，可以在嘗試切換4g/wifi。",
						"2.廣告需要一定時間加載，請不要一進遊戲就立即點擊廣告。請在網絡暢通的情況下玩一玩遊戲，再次觀看廣告。",
						"如果還是不行，請聯繫我們，告訴我們您的玩家ID和哪個位置的廣告無法加載。",
						"不恰當廣告：",
						"如果遊戲中出現了不合適的廣告（例如暴力色情或者賭博廣告），請發送郵件至punball@habby.com給我們！並附帶以下信息：",
						"1.請在郵件中附帶：截圖廣告中出現的不合適的畫面或者錄製該廣告視頻",
						"2.點擊該廣告跳轉到商店頁面的截圖或屏幕錄製",
						"3.您的玩家ID，我們將在核實後給予您一定鑽石獎勵"
					]
				}
			]
		},
		{
			title: "反饋問題"
		}
	],
	"feedSubmit": {
		"contactUS": "聯繫我們",
		"contentPlaceholder": "請描述您的問題",
		"emailPlaceholder": "請留下您的電子郵箱方便我們聯繫您：",
		"submit": "提交",
		"success": "您的資訊已經提交，感謝您的回饋。",
		"returnToGame": "返回遊戲"
	}
}