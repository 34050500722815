"use strict";

// 德语
export default {
	"backGame": "Rückkehr",
	"title": "Wählen Sie Problem",
	"issues": [
		{
			"title": "Probleme beim Speichern",
			"list": [
				{
					"issueTitle": "Wie kann ich den Spielfortschritt speichern?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Du kannst Punball mit einem Game Center-Konto oder einem Play Spiele-Konto öffnen.",
						"<b>Als Besucher anmelden</b>：Wenn du das Spiel zum ersten Mal spielst und dich nicht in deinem Game Center-Konto (iOS) oder Play Spiele-Konto (Android) angemeldet hast, wirst du als Besucher angemeldet. Die Speicherdaten deines Besucherkontos sind mit deinem Smartphone verbunden, so dass du die Speicherdaten nach dem Löschen und erneuten Herunterladen des Spiels nicht verlierst. Die Speicherdaten deines Besucherkontos können jedoch nicht auf andere Geräte übertragen werden. Hinweis: Es besteht die Möglichkeit, dass du deine Speicherdaten verlierst, wenn du eine Rücksetzung auf die Werkseinstellungen, ein Flashen oder ein Jailbreaking deines Geräts durchführst. Wir empfehlen dir die Anmeldung in deinem Game Center-Konto (iOS) oder in deinem Play Spiele-Konto (Android), um zu vermeiden, dass du die Speicherdaten deines Besucherkontos verlierst.",
						"<b>Kontoanmeldung</b>：Wenn du dich vor deinem ersten Spiel in deinem Game Center-Konto (iOS) oder Play Spiele-Konto (Android) anmeldest, öffnest du das Spiel standardmäßig mit deinem Konto. Dein Spielfortschritt wird automatisch in deinem Game Center-Konto (iOS) oder in deinem Play Spiele-Konto (Android) gespeichert."
					],
				}, 
				{
					"issueTitle": "Ich möchte die Speicherdaten auf andere Geräte übertragen.",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Du kannst deine Speicherdaten über dein Game Center-Konto (iOS) oder dein Play Spiele-Konto (Android) auf andere Geräte übertragen. Die Speicherdaten können jedoch derzeit nur auf Geräte mit dem gleichen Betriebssystem übertragen werden. Zum Beispiel iOS zu iOS und Android zu Android.",
						"Methode: Vergewissere dich, dass du dich auf deinem alten Gerät in deinem Game Center-Konto (iOS) oder in deinem Play Spiele-Konto (Android) angemeldet hast. Melde dich auf einem neuen Gerät beim gleichen Game Center-Konto (iOS) oder Play Spiele-Konto (Android) an und lade das Spiel herunter. Wenn du das Spiel startest, erkennt es dein Konto und lädt die Speicherdaten herunter. (Android-Benutzer müssen das Spiel in Play Spiele ausführen)."
					]
				},
				{
					"issueTitle": "Ich möchte ein neues Spiel starten.",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>Ich möchte meinen bisherigen Speicherstand behalten</b>：Vergewissere dich, dass du dich in deinem Game Center-Konto (iOS) oder in deinem Play Spiele-Konto (Android) angemeldet hast. Wenn ja, werden die mit deinem Konto verbundenen Speicherdaten automatisch auf unserem Server gespeichert. Melde dich jetzt von deinem Konto ab, lösche das Spiel und lade es noch einmal herunter. Melde dich dann bei einem neuen Konto an (das noch nie benutzt wurde, um Punball zu öffnen), um einen neuen Speicherstand zu erstellen. Wenn du mit dem alten Konto spielen möchtest, wechsle zurück zu deinem vorherigen Game Center-Konto (iOS) oder Play Spiele-Konto (Android), lade das Spiel noch einmal herunter und starte es. Das System erkennt und lädt deinen vorherigen Speicherstand.",
						"<b>Ich möchte meinen Speicherstand nicht mehr haben</b>：In den Spieleinstellungen unter „Kontakt“ kannst du uns mitteilen, dass du deinen Speicher löschen und ein neues Spiel starten möchtest. Wir löschen deine Daten innerhalb von 1-2 Werktagen nach Erhalt deiner Nachricht. Speicherdaten können nach dem Löschen nicht mehr abgerufen werden, also denk bitte gut darüber nach. Wir raten dir davon ab, deinen Speicherstand zu löschen, da du im Spiel besser wirst, wenn du mehr spielst. Es ist keine gute Idee, ein neues Spiel zu starten."
					]
				},
				{
					"issueTitle": "Ich habe meinen Fortschritt verloren.",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"Wir sperren täglich die Konten von Cheatern und löschen ihre Speicherdaten. Es tut uns sehr leid, wenn deine Speicherdaten gelöscht wurden. Wir wünschen uns, dass jeder das Spiel auf einem legalen Weg herunterlädt und keine Cheats verwendet, um dir und allen anderen ein gutes Spielerlebnis zu garantieren.",
						"<b>Ich habe nicht gecheated, aber mein Fortschritt ist weg</b>：",
						"<b>Besucherkonto</b>：Vielleicht bist du bei einem Game Center-Konto (iOS) oder Play Spiele-Konto (Android) angemeldet, das mit einem anderen Speicherstand verknüpft ist. Wenn du zu deinem Besucherkonto-Speicherstand zurückkehren möchtest, melde dich von deinem Konto ab, lösche das Spiel und lade es noch einmal herunter. (Wir empfehlen dir, dich bei einem neuen Konto anzumelden, um zu vermeiden, dass du die Speicherdaten verlierst.)",
						"<b>Ich habe mich in meinem Konto angemeldet, aber meine Speicherdaten sind weg</b>：Kontrolliere, ob du die Konten gewechselt hast. Wenn du zu einem anderen Game Center-Konto (iOS) oder Play Spiele-Konto (Android) gewechselt hast, kannst du über deine Telefoneinstellungen zum vorherigen Konto zurückkehren.",
						"<b>Wenn du dein Konto nicht wiederherstellen kannst</b>：Nimm jederzeit Kontakt mit uns auf und sende uns:",
						"1. Die Benutzer-ID deines vorherigen Kontos",
						"2. Dein aktuelles Konto",
						"3. Deine E-Mail-Adresse",
						"4. Was ist passiert, bevor du dein Konto verloren hast?",
						"Wenn du mit deinem bisherigen Konto Einkäufe in der App getätigt hast und nicht auf das Konto zugreifen kannst, können wir alle deine gekauften Artikel aus deinem vorherigen Konto auf das neue Konto übertragen. Dazu musst du uns die folgenden Informationen geben:",
						"1. Die Benutzer-ID deines neuen Kontos",
						"2. Was du gekauft hast und die Menge",
						"3. Alle Screenshots oder Bestell-IDs deiner Transaktionen (Wir akzeptieren nur Apple- oder Google-Rechnungen. Bitte gib keine anderen Zahlungsinformationen an, wie etwa die Informationen deiner Kreditkarten oder Zahlungstexte.)Schicke die oben aufgeführten Informationen an unsere E-Mail-Adresse: punball@habby.com. Nach der Überprüfung schicken wir dir alle Artikel, die du gekauft hast, auf dein neues Konto."
					]
				},
				{
					"issueTitle": "Kann ich das Spiel auf mehreren Geräten spielen?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>Ja, aber wir raten davon ab</b>：Wenn du deinen Spielfortschritt zwischen mehreren Geräten synchronisieren möchtest, melde dich auf deinen Geräten einfach im gleichen Game Center-Konto (iOS) oder Google Play-Konto (Android) an. Aber wir empfehlen nicht, dies zu tun. Wenn deine Geräte den gleichen Speicherplatz teilen und du eine kostenlose Truhe auf Gerät A öffnest und sie auf Gerät B noch einmal öffnest, werden deine Juwelen abgezogen, weil du die Truhe auf Gerät A bereits geöffnet hast. Damit du keine Edelsteine versehentlich verlierst, empfehlen wir dir, das Spiel nicht gleichzeitig auf mehreren Geräten zu spielen."
					]
				}
			]
		}, 
		{
			"title": "Probleme beim Kauf",
			"list": [
				{
					"issueTitle": "Warum habe ich den von mir gekauften Artikel nicht erhalten?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Bitte überprüfe deine Netzwerkverbindung. Beende das Spiel und starte es später neu.",
						"Wenn du dir sicher bist, dass du den Artikel nicht erhalten hast, kontaktiere uns bitte mit den folgenden Informationen:",
						"1.<b>Deine Spieler-ID</b> (in den Spieleinstellungen)",
						"2.<b>Was du gekauft hast und die Menge</b>",
						"3.<b>Screenshots oder Bestell-IDs deiner Transaktion</b>(Wir akzeptieren nur Apple- oder Google-Rechnungen. Bitte gib keine anderen Zahlungsinformationen an, wie etwa die Informationen deiner Kreditkarten oder Zahlungstexte.)Bitte schicke die oben aufgeführten Informationen an unsere E-Mail-Adresse: punball@habby.com.Wir senden dir nach Erhalt und Überprüfung deiner Daten ein Paket zu. Die Bearbeitung dauert ca. 2-3 Werktage. Bitte habe etwas Geduld und schicke uns nicht mehrmals die gleiche Nachrichten. Vielen Dank für deine Mithilfe."
					]
				},
				{
					"issueTitle": "Fehler in der Anzahl der Juwelen und Münzen",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Wenn du bei der Anzahl deiner Münzen oder Juwelen einen Fehler bemerkst, starte bitte zuerst die App neu und überprüfe deine Verbindung. Überprüfe, ob du dich auf mehreren Geräten im Spiel angemeldet hast, und ob du Münzen oder Juwelen auf diesen Geräten verbraucht hast.",
						"Wenn du dir sicher bist, dass es einen Fehler in der Anzahl der Münzen oder Juwelen gibt, kontaktiere uns bitte mit den folgenden Informationen:",
						"1. Deine Spieler-ID und die Anzahl der verlorenen Münzen oder Juwelen.",
						"2. Sende alle Screenshots oder Bildschirmaufzeichnungen bitte an punball@habby.com. Es hilft uns dabei, das Problem zu identifizieren und dir schneller eine Entschädigung zu schicken.",
						"3. Teile uns mit, was du getan hast, bevor du die Gegenstände verloren hast (Neuinstallation, Aktualisierung oder Start des Spiels auf neuen Geräten).",
					]
				},
				{
					"issueTitle": "Ich möchte eine Rückerstattung haben.",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Nachfolgend findest du unsere Rückerstattungsrichtlinie. Eine Rückerstattung erfolgt nur, wenn die nachstehenden Bedingungen erfüllt sind:",
						"1. Eine Rückerstattungsanfrage wird nur akzeptiert, wenn sich die von dir gekauften Juwelen, Münzen oder andere Ingame-Items noch in deinem Konto befinden und noch nicht verwendet wurden.",
						"2. Wenn dir zuvor eine Rückerstattung für einen gekauften Artikel gewährt wurde, werden alle anderen Käufe, die du abschließt, nicht erstattet.",
						"3. Eine Rückerstattung kann nur erfolgen, wenn du den Rückerstattungsantrag innerhalb von 7 Tagen nach dem Kauf stellst. Jeder Rückerstattungsantrag, der später als 7 Tage nach dem Kauf gestellt wird, wird abgelehnt.",
						"4. Wenn du unsere Bedingungen für die Rückerstattung erfüllst, kontaktiere uns bitte und schicke uns einen Screenshot oder die Bestell-ID deiner Transaktion und deine Spieler-ID im Spiel. Unsere E-Mail-Adresse ist: punball@habby.com. Wir überprüfen deinen Rückerstattungsantrag nach Erhalt deiner E-Mail und zahlen dir die Rückerstattung, sobald der Antrag genehmigt ist. Die Bearbeitung dauert ca. 2-3 Werktage. Bitte habe etwas Geduld und schicke uns nicht mehrmals die gleiche Nachrichten. Vielen Dank für deine Mithilfe.Hinweis: Wenn dein Konto oder Gerät von anderen genutzt wurde oder wenn du anderen erlaubt hast, Einkäufe über dein Konto zu tätigen, tragen wir keine Verantwortung für die auf deinem Konto getätigten Einkäufe. In diesem Fall wird keine Rückerstattung gewährt. Vielen Dank."
					]
				},
				{
					"issueTitle": "Wie vermeide ich versehentliche Einkäufe?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Punball ist ein Spiel, das weltweit kostenlos heruntergeladen werden kann. Im Ingame-Shop können jedoch Pakete und Juwelen gekauft werden. Wenn du versehentliche Käufe vermeiden möchtest, ändere bitte die Einstellungen deines Geräts.iOS-Geräte:",
						"1. Rufe „Einstellungen > Allgemein > Einschränkungen“ auf.",
						"2. Wähle „Einschränkungen aktivieren“ aus.",
						"3. Gib einen Passcode ein. Es wird empfohlen, einen anderen Passcode als den Passcode für die Entsperrung deines Geräts festzulegen.",
						"4. Sobald du Einschränkungen aktiviert hast, siehst du eine Liste von Aktionen, die deine Apps ausführen dürfen. Wenn du In-App-Käufe ausschließen möchtest, schalte bitte die entsprechende Option aus.",
						"Wenn du In-App-Käufe nicht vollständig ausschließen willst, kannst du die Passworteinstellungen deiner Apps ändern. Sobald du die Einschränkungen deaktiviert hast, rufe „Einstellungen > App Store > Passworteinstellungen“ auf.",
						"iOS 12.3 und höher:",
						"1. Rufe „Einstellungen > Bildschirmzeit > Inhalts- und Datenschutzbeschränkungen“ auf.",
						"2. Aktiviere „Inhalts- und Datenschutzbeschränkungen“.",
						"3. Tippe auf „iTunes- und App Store-Einkäufe“.",
						"4. Tippe auf „Immer erforderlich“ oder auf „In-App Käufe > Nicht zulassen“.",
						"Android-Geräte:",
						"1. Öffne den Play Store.",
						"2. Rufe die Einstellungen auf.",
						"3. Tippe auf „Authentifizierung für Einkäufe erforderlich“.",
						"4. Wähle eine Option außer „Niemals“ aus.",
						"Alle In-App-Käufe über Google Play benötigen nun eine Authentifizierung.",
						"Hinweis: Wenn du „Alle 30 Minuten“ auswählst, wird für alle Einkäufe innerhalb von 30 Minuten kein Passwort benötigt, nachdem du das Passwort eingegeben und eine Transaktion abgeschlossen hast."
					]
				}
			]
		},
		{
			"title": "Netzwerkprobleme",
			"list": [
				{
					"issueTitle": "Energiefehler",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Bitte überprüfe die folgenden Punkte:",
						"1. Überprüfe dein Netzwerk und ob dein Gerät mit dem Internet verbunden ist.",
						"2. Überprüfe, ob du das Spiel auf mehreren Geräten ausführst und Energie auf diesen Geräten verbrauchst.",
						"3. Überprüfe, ob du die Ortszeit deines Geräts geändert hast. Wir kümmern uns nicht um Energieprobleme, die durch die Änderung der Zeiteinstellungen entstehen.",
						"Wenn der Fehler weiterhin besteht, kontaktiere uns bitte und teile uns folgendes mit:",
						"1. Die spezifischen Werte deiner aktuellen Energie und der Energiefluktuationen.",
						"2. Wann genau sind die Energiefluktuationen aufgetreten?",
						"3. Deine Spieler-ID.",
						"Nach der Prüfung deines Problems werden wir dir eine angemessene Entschädigung zukommen lassen. Die Bearbeitung dauert ca. 2-3 Werktage. Bitte habe etwas Geduld und schicke uns nicht mehrmals die gleiche Nachrichten. Vielen Dank für deine Mithilfe."
					]
				},
				{
					"issueTitle": "Probleme mit Werbung",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Es ist nicht möglich, Werbung anzusehen:",
						"Bitte überprüfe die folgenden Punkte:",
						"1. Vergewissere dich, dass das Netzwerk deines Telefons funktioniert. Du kannst versuchen, zu 4G/WLAN zu wechseln.",
						"2. Da das Laden einer Werbung Zeit in Anspruch nimmt, tippe bitte nicht sofort nach dem Start des Spiels auf eine Werbung. Wenn die Netzwerkverbindung gut ist, spiele das Spiel bitte eine Weile, dann schau dir die Werbung noch einmal an.",
						"Wenn das Problem dadurch nicht behoben wird, kontaktiere uns bitte, nenne uns deine Spieler-ID und wo sich die Werbung befindet, die nicht geladen werden konnte.",
						"Unangemessene Apps:",
						"Bei unangemessener Werbung im Spiel (z. B. mit Themen wie Gewalt oder Glücksspiel), sende uns bitte eine E-Mail an punball@habby.com mit den folgenden Informationen:",
						"1. Hänge einen Screenshot des unangemessenen Inhalts der Werbung oder ein Video der Werbung an die E-Mail an.",
						"2. Ein Screenshot oder eine Bildschirmaufzeichnung der Seite des Sponsors, auf die die Werbung weiterleitet.",
						"3. Deine Spieler-ID. Nach der Überprüfung belohnen wir dich mit ein paar Juwelen."
					]
				}
			]
		},
		{
			title: "Kontaktiere uns"
		}
	],
	"feedSubmit": {
		"contactUS": "Kontakt",
		"contentPlaceholder": "Bitte beschreibe dein Problem",
		"emailPlaceholder": "Bitte hinterlasse deine E-Mail, damit wir dich kontaktieren können:",
		"submit": "Senden",
		"success": "Deine Nachricht wurde erfolgreich versendet. Vielen Dank für dein Feedback.",
		"returnToGame": "Zurück zum Spiel"
	}
}