"use strict";
import ejs from 'ejs';


function initContent(issue) {
	console.log(issue);
	switch (issue.issueAnswerType) {
		case 1:
			return initType1(issue);
		case 2:
			return initType2(issue);
		case 3:
			return initType3(issue);
		case 4:
			return initType4(issue);
	}
}

function initType1(issue) {
	const template = `
	<% for(var i = 0; i < issueAnswer.length; i++) {%>
	<p><%- issueAnswer[i] %></p>
	<% } %>
`;

	return ejs.render(template, {issueAnswer: issue.issueAnswer});
}

function initType2(issue) {
	const template = `
	<div class="prefix">
		<% for(var i = 0; i < prefix.length; i++) {%>
		<p ><%= prefix[i] %></p>
		<% } %>
	</div>
	
	<ul>
		<% for(var i = 0; i < list.length; i++) {%>
		<li><%= list[i] %></li>
		<% } %>
	</ul>
	
	<div  class="postfix">
		<% for(var i = 0; i < postfix.length; i++) {%>
		<p><%= postfix[i] %></p>
		<% } %>
	</div>
`;
	return ejs.render(template, issue.issueAnswer);
}

function initType3(issue) {
	const template = `
	<% for(var i = 0; i < issueAnswer.length; i++ ) {%>
	<p class="prefix"><%= issueAnswer[i].prefix %></p>
	<ul>
		<% for(var j = 0; j < issueAnswer[i].list.length; j++ ) {%>
		<li><%= issueAnswer[i].list[j] %></li>	
		<% } %>
	</ul>	
	<% } %>	
`;

	return ejs.render(template, {issueAnswer: issue.issueAnswer});
}

function initType4(issue) {
	const template = `
	<p><%= issueAnswer.header %></p>
	<% for(var i = 0; i < issueAnswer.body.length; i++ ) {%>
	<% var body = issueAnswer.body[i] %>
	<p class="prefix"><%= body.prefix %></p>
	<ul>
		<% for(var j = 0; j < body.list.length; j++ ) {%>
		<li><%= body.list[j] %></li>
		<% } %>
	</ul>
	<% } %>

`;

	return ejs.render(template, {issueAnswer: issue.issueAnswer});
}

export default {
	initContent
}