"use strict";

export default {
	"backGame": "返回游戏",
	"title": "选择问题",
	"issues": [
		{
			"title": "存档问题",
			"list": [
				{
					"issueTitle": "我们如何保存游戏进度？",
					"issueAnswerType": 1,
					"issueAnswer": [
						"《Punball》使用 Game Center 和 Play游戏 来登陆游戏。",
						"<b>游客登陆</b>：如果您是首次登录且未登录GameCenter（iOS）或Play游戏（Android），您将以游客状态进入游戏。游客身份下，进度根据当前手机进行保存，删除重新下载进度也不会丢失，但是游客身份无法将进度转移至其他设备。请注意：当手机进行恢复出厂、刷机、越狱等操作时，游客进度可能会丢失。为了避免您的游客存档意外丢失，所以我们建议您登陆账号。",
						"<b>账号登陆</b>：如果您的首次进入游戏之前登录了GameCenter（iOS）或Play游戏（Android），默认为账号登陆，您的游戏记录将自动保存至您的GameCenter（iOS）或Play游戏（Android）。"
					],
				}, 
				{
					"issueTitle": "我想将存档转移至其他设备？",
					"issueAnswerType": 1,
					"issueAnswer": [
						"我们可以通过GameCenter（iOS）或Play游戏（Android）将游戏账号转移至其他设备，但目前只能在相同平台下进行转移。例如：IOS转移至IOS，安卓转移至安卓。",
						"操作方法：确保旧设备已经登陆了GameCenter（iOS）或Play游戏（Android）账号，然后在新设备上登陆相同的GameCenter（iOS）或Play游戏（Android）账号并下载游戏。然后进入游戏后会检测到已登陆的账号并恢复游戏进度。（安卓需要从Play 游戏启动登陆游戏）"
					]
				},
				{
					"issueTitle": "我想重新开始玩游戏",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>我还想保留我当前的进度</b>：您必须确保已经登陆GameCenter（iOS）或Play游戏（Android）账号，登陆过账号的存档会自动保存至我们的服务器。此时，你可以退出你的账号，然后删除游戏重新下载，并登陆一个新的账号（从未登陆过Punball的账号），此时将会为你创建一个新的存档。如果你想玩回老账号，切换回原来的GameCenter（iOS）或Play游戏（Android）账号，再次重新下载，再次进入游戏之后，系统会识别到您之前的存档并加载。",
						"<b>我不想要我的存档了</b>：可以通过游戏设置中的联系我们告诉我们您需要删除存档重新开始。我们的工作人员收到后会在1至2个工作日内为你清除存档。清除存档无法重新开始，请慎重考虑。并且我们不建议您清除存档，因为您通过游戏时间的积累会越来越强，重新开始并不是一个好的想法。"
					]
				},
				{
					"issueTitle": "我丢失了我的进度",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"我们每日会对使用了作弊手段进行游戏的玩家进行封号删档处理。如果您被删档了，我们感到非常抱歉。希望大家使用正规渠道下载游戏，拒绝任何作弊行为，以免影响你和他人的游戏体验。",
						"<b>我没有任何作弊行为，但丢失了游戏进度</b>：",
						"<b>游客身份</b>：您可能登陆了一个已经被其他存档占用的GameCenter（iOS）或Play游戏（Android）账号。此时退出账号，删除重新下载，您的游客存档将找回。（我们还是建议您登陆一个新的账号，确保游戏存档不再丢失）",
						"<b>已登陆账号，但记录丢失</b>：先确认您是否切换过账号，如果切换了GameCenter（iOS）或Play游戏（Android）账号，请在手机设置中切换回来。",
						"<b>如果您还是无法找回自己的账号</b>：请随时与我们联系，并且告诉我们：",
						"1.您旧账号的用户ID",
						"2.您当前的账号",
						"3.您的邮箱",
						"4.您丢失账号之前都经历了什么？",
						"如果您有充值过，并且无法找回，我们可以将您在旧账户的所有充值补偿至您的新账户，我们需要您提供以下信息：",
						"1.您新账户的用户ID",
						"2.你购买了什么物品，数量",
						"3.所有的交易记录截图或订单号ID（我们只接受Apple或Google账单，请勿提供您支付卡付款信息或付款短信等其他付款信息）您可将以上信息发送到我们的邮箱punball@habby.com联系我们，我们会在审核成后后将您充值过的所有物品补偿至您的新账户。"
					]
				},
				{
					"issueTitle": "我可以在多个设备上玩游戏吗?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>可以，但我们不建议这么做</b>：您只需在多个设备登录同一个GameCenter（iOS）或GooglePlay（Android）账号，即可共享游戏进度。但我们不建议这么做，因为在同一个存档下：假设在设备A使用了免费开宝箱的次数之后，在设备B上再次开启免费宝箱，实际上会扣除钻石，因为你在A设备已经开过一次了。为了避免出现宝石被意外扣除的情况，我们建议您不要在多个设备上同时玩游戏。"
					]
				}
			]
		}, 
		{
			"title": "充值问题",
			"list": [
				{
					"issueTitle": "我充值了，但为什么没有收到物品？",
					"issueAnswerType": 1,
					"issueAnswer": [
						"请检查您的网络链接，并尝试退出游戏并稍后重新进入。",
						"如果确定没有收到，请您联系我们，提供以下信息：",
						"1.<b>你的玩家ID</b>（在游戏设置页中查看）",
						"2.<b>你购买了什么物品，数量</b>",
						"3.<b>交易记录截图或订单号ID</b>（我们只接受Apple或Google账单，请勿提供您支付卡付款信息或付款短信等其他付款信息）您可将以上信息发送到我们的邮箱punball@habby.com联系我们, 我们在收到您发送的信息并核实之后会为您补发礼包，大概需要2至3工作日，请根据实际时间耐心等待，请不要多次给我们发送相同信息。感谢您的配合。"
					]
				},
				{
					"issueTitle": "我的钻石和金币数量发生异常？",
					"issueAnswerType": 1,
					"issueAnswer": [
						"如果您发现您的金币，钻石数量异常，请您先尝试重启客户端并确认联网。确认您的账户是否登陆了多设备，是否在其他设备上消耗掉了。",
						"如果您确认金币，钻石数量异常，请联系我们并提供以下信息：",
						"1.您的玩家ID，您失去的金币或钻石数量。",
						"2.如果您有截图或者屏幕录制，请发送邮件至punball@habby.com联系我们，这样能更快帮助我们定位问题和对您进行补偿。",
						"3.联系我们告知您在丢失物品之前都做了些什么（重新安装、更新、或者登陆新设备）",
					]
				},
				{
					"issueTitle": "我想退款",
					"issueAnswerType": 1,
					"issueAnswer": [
						"以下是我们游戏处理退款的政策。只有满足下列条件才能办理退款：",
						"1.购买后，只有当您购买的钻石、金币或其他游戏中的物品仍在账户中，并且没有任何消耗的时候，我们才接受退款。",
						"2.如果你曾经购买了一件商品并退款了，我们将不再为您的购买进行退款。",
						"3.任何购买将只在购买后的7天内提出要求将有可能被退款。超过7天之后，退款将不被接受。",
						"4.如果您符合我们的退款条件，请联系我们并提供您的交易记录截图或订单号ID和您游戏内的玩家ID，我们的邮箱是：punball@habby.com我们在收到您发送的邮件之后会进行审核，审核成功后我们会为您办理退款，大概需要2至3工作日，请根据实际时间耐心等待，请不要多次给我们发送相同信息。感谢您的配合。请注意：如果您的帐户或设备被他人使用，或如果您允许他人为您的账户进行购买，我们对您账户的购买行为没有任何责任，此时我们将不处理您的退款。谢谢各位。"
					]
				},
				{
					"issueTitle": "如何避免不小心意外购买？",
					"issueAnswerType": 1,
					"issueAnswer": [
						"《Punball》是一款全球免费下载游戏，但游戏内商城里的礼包和钻石可付费购买。如果您需要避免意外购买，请调整您的设备设置。IOS设备：",
						"1.前往手机设置->通用->访问限制",
						"2.选择启动访问限制",
						"3.输入访问限制选项密码并确认，我们建议您设置与手机解锁密码不同的设备密码",
						"4.启用后，您会看到您的设备允许的应用程序的行为清单。若您要限制应用程序内购买，请关闭对应选项。",
						"若您不要完全限制应用程序内购买，可调整各应用程序的密码设定。确认停用访问限制后，前往设置->App Store -> 密码设置",
						"IOS 12.3及以上",
						"前往手机设置->屏幕使用时间->内容和隐私访问限制",
						"1.选择启用内容和隐私访问限制",
						"2.APP内购买项目选择不允许或始终需要密码",
						"Android设备",
						"1.启动 Play Store",
						"2.找到 设置",
						"3.点击 购买时验证身份 按钮",
						"4.选择除“一律不”之外的其他选项",
						"此时，通过Google Play进行应用程序内购买时，需要验证。",
						"请注意：如果选择“每30分钟”，在输入密码后交易后的30分钟内，任何购买都不需要输入密码。"
					]
				}
			]
		},
		{
			"title": "网络问题",
			"list": [
				{
					"issueTitle": "体力出现异常",
					"issueAnswerType": 1,
					"issueAnswer": [
						"请确认以下问题：",
						"1.请确认您的网络，是否联网。",
						"2.请确认您是否登陆了多台设备，并在其他设备上消耗了体力。",
						"3.请确认您是否调整过本地设备时间，调整时间出现的体力问题我们将不予处理。",
						"如果您还是异常，请联系我们，并告诉我们：",
						"1.您和体力异常波动的具体数值？",
						"2.您在具体什么时间段出现过体力异常？",
						"3.您的玩家ID",
						"我们会对您的问题核实后进行一定补偿，大概需要2至3工作日，请根据实际时间耐心等待，请不要多次给我们发送相同信息。感谢您的配合。"
					]
				},
				{
					"issueTitle": "广告出现问题?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"无法观看广告：",
						"请确认以下问题：",
						"1.手机网络情况正常，可以在尝试切换4g/wifi。",
						"2.广告需要一定时间加载，请不要一进游戏就立即点击广告。请在网络畅通的情况下玩一玩游戏，再次观看广告。",
						"如果还是不行，请联系我们，告诉我们您的玩家ID和哪个位置的广告无法加载。",
						"不恰当广告：",
						"如果游戏中出现了不合适的广告（例如暴力色情或者赌博广告），请发送邮件至punball@habby.com给我们！并附带以下信息：",
						"1.请在邮件中附带：截图广告中出现的不合适的画面或者录制该广告视频",
						"2.点击该广告跳转到商店页面的截图或屏幕录制",
						"3.您的玩家ID，我们将在核实后给予您一定钻石奖励"
					]
				}
			]
		},
		{
			title: "反馈问题"
		}
	],
	"feedSubmit": {
		"contactUS": "联系我们",
		"contentPlaceholder": "请描述您的问题",
		"emailPlaceholder": "请留下您的电子邮箱方便我们联系您：",
		"submit": "提交",
		"success": "您的信息已经提交，感谢您的反馈。",
		"returnToGame": "返回游戏"
	}
}