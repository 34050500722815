"use strict";

// 西班牙
export default {
	"lang": "es",
	"backGame": "Vuelta",
	"title": "Pregunta de elección",
	"issues": [
		{
			"title": "Problemas de guardado",
			"list": [
				{
					"issueTitle": "¿Cómo guardo el progreso en el juego?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Puedes entrar en Punball con una cuenta de Game Center o Play Games.",
						"<b>Inicia sesión como visitante</b>：Entrarás en el juego como visitante si es la primera vez que juegas y no has iniciado sesión con tu cuenta de Game Center (iOS) o Play Games (Android). Los datos guardados de tu cuenta de visitante están asociados a tu teléfono, por lo que no perderás la información guardada si borras y vuelves a descargar el juego. Sin embargo, los datos guardados de tu cuenta de visitante no se pueden transferir a otros dispositivos. Ten en cuenta que puedes perder los datos guardados si restableces los ajustes de fábrica, entras en modo superusuario en el teléfono, etc. Te sugerimos que inicie sesión en tu cuenta de Game Center (iOS) o Play Games (Android) para evitar perder la seguridad de tu cuenta de visitante.",
						"<b>Inicio de sesión en cuenta</b>：Si inicias sesión en tu cuenta de Game Center (iOS) o Play Games (Android) antes de tu primera partida, entrarás en el juego con tu cuenta de forma predeterminada. Tu progreso en el juego se guardará automáticamente en tu cuenta de Game Center (iOS) o Play Games (Android)."
					],
				}, 
				{
					"issueTitle": "Quiero transferir mi partida guardada a otros dispositivos.",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Puedes transferir tu partida guardada a otros dispositivos a través de tu cuenta de Game Center (iOS) o Play Games (Android). Sin embargo, por el momento los datos guardados solo se pueden transferir a dispositivos con el mismo sistema operativo. Por ejemplo, iOS a iOS, Android a Android.",
						"Método: Asegúrate de que has iniciado sesión en tu cuenta de Game Center (iOS) o Play Games (Android) en tu antiguo dispositivo. Inicia sesión en la misma cuenta de Game Center (iOS) o Play Games (Android) en un dispositivo nuevo y descarga el juego. Cuando ejecutes el juego, detectará tu cuenta y recuperará los datos guardados. (Los usuarios de Android necesitan ejecutar el juego en Play Games.)"
					]
				},
				{
					"issueTitle": "Quiero empezar una nueva partida.",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>Quiero conservar mi partida actual</b>：Asegúrate de que has iniciado sesión en tu cuenta de Game Center (iOS) o Play Games (Android). Si es así, los datos guardados asociados a su cuenta se guardan automáticamente en nuestro servidor. Después, cierra sesión en tu cuenta, borra y vuelve a descargar el juego. Luego, inicia sesión con una nueva cuenta (que nunca hayas usado para iniciar sesión en Punball) para crear una nueva partida. Para jugar en la cuenta anterior, vuelve a cambiar a tu cuenta anterior de Game Center (iOS) o Play Games (Android), vuelve a descargar y ejecutar el juego. El sistema detectará y cargará tu partida guardada anterior.",
						"<b>Ya no quiero la partida guardada.</b>：Ve a \"Contacto\" en los ajustes del juego para indicarnos que quieres borrar la partida guardada y empezar una nueva partida. Borraremos la partida guardada en un plazo de 1 a 2 días laborables después de recibir el mensaje. Los datos guardados no se pueden recuperados una vez borrados, así que por favor piénsalo bien. No es aconsejable eliminar el archivo guardado, ya que mejorarás en el juego cuanto más juegues. No es una buena idea empezar una nueva partida."
					]
				},
				{
					"issueTitle": "He perdido mi progreso",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"Bloqueamos las cuentas de los tramposos y borramos sus partidas a diario. Sentimos mucho que se haya borrado tu partida. Esperamos que todo el mundo descargue el juego a través de un canal adecuado y no utilice trucos para asegurar que tú y los demás jugadores tengáis una buena experiencia de juego.",
						"<b>No he hecho trampa, pero he perdido mi progreso</b>：",
						"<b>Cuenta de visitante</b>：Es posible que hayas iniciado sesión en una cuenta de Game Center (iOS) o Play Games (Android) asociada con otra cuenta guardada. Para recuperar los datos de tu cuenta de visitante, cierra sesión en la cuenta, borra y vuelve a descargar el juego. (Te sugerimos que inicies sesión en una nueva cuenta para evitar perder la partida.)",
						"<b>He iniciado sesión en mi cuenta, pero he perdido la partida</b>：Comprueba si has cambiado de cuenta. Si has cambiado a otra cuenta de Game Center (iOS) o Play Games (Android), vuelve a la cuenta anterior a través de los ajustes del teléfono.",
						"<b>Si no puedes recuperar la cuenta</b>：Por favor, no dudes en ponerte en contacto con nosotros en cualquier momento e indícanos:",
						"1. El ID de usuario de tu cuenta anterior",
						"2. Tu cuenta actual",
						"3. Tu dirección de correo electrónico",
						"4. Qué pasó antes de que perdieras la cuenta",
						"Si realizaste compras en la aplicación en su cuenta anterior y no puedes recuperarlas, podemos transferir todos los artículos comprados en su cuenta anterior a la nueva. Debes proporcionarnos la siguiente información:",
						"1. El ID de usuario de tu cuenta nueva",
						"2. Lo que compraste y la cantidad",
						"3. Todas las capturas de pantalla o ID de pedido de tus transacciones (Solo aceptamos facturas de Apple o Google. Por favor, no proporciones otra información de pago, como la información de tus tarjetas de pago o textos de pago.)Envía la información anterior a nuestro correo electrónico: punball@habby.com. Tras la verificación, te devolveremos todos los artículos que compraste a tu nueva cuenta."
					]
				},
				{
					"issueTitle": "¿Puedo jugar en varios dispositivos?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>Puedes, pero no es aconsejable</b>： Para sincronizar el progreso del juego entre varios dispositivos, solo tienes que acceder a la misma cuenta de Game Center (iOS) o Google Play (Android) en tus dispositivos. Pero no te recomendamos que lo hagas. Cuando tus dispositivos comparten la misma partida, si abres un cofre gratis en el dispositivo A y lo abres de nuevo en el dispositivo B, las gemas se descontarán porque ya has abierto el cofre en el dispositivo A. Para evitar la pérdida accidental de gemas, te aconsejamos que no juegues a la vez en varios dispositivos."
					]
				}
			]
		}, 
		{
			"title": "Problemas con las compras",
			"list": [
				{
					"issueTitle": "¿Por qué no he recibido el artículo que he comprado?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Comprueba tu conexión a la red. Sal del juego y vuelve a intentarlo más tarde.",
						"Si no estás seguro de si has recibido el artículo, ponte en contacto con nosotros y proporciónanos la siguiente información:",
						"1.<b>Tu ID de jugador</b>(en los ajustes de juego)",
						"2.<b>Lo que compraste y la cantidad</b>",
						"3.<b>Todas las capturas de pantalla o ID de pedido de tus transacciones </b>(solo aceptamos facturas de Apple o Google. Por favor, no proporciones otra información de pago, como la información de tus tarjetas de pago o textos de pago.)Envía la información anterior a nuestro correo electrónico: punball@habby.com.Te enviaremos el paquete después de recibir y verificar tu información. El plazo de entrega es de 2 a 3 días laborables. Por favor, ten paciencia y no nos envíes el mismo mensaje varias veces. Gracias por tu cooperación."
					]
				},
				{
					"issueTitle": "Error en el número de gemas y monedas.",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Si adviertes un error en el número de tus monedas o gemas, por favor reinicia la aplicación y comprueba la conexión. Comprueba si has iniciado sesión en el juego con varios dispositivos y si has usado monedas y gemas en esos dispositivos.",
						"Si no estás seguro de si existe un error en el número de monedas o gemas, ponte en contacto con nosotros y proporciónanos la siguiente información:",
						"1. Tu ID de jugador y el número de monedas o gemas que has perdido.",
						"2. Envía cualquier captura o grabación de pantalla a punball@habby.com. Nos ayudará a identificar el problema y ofrecerte una compensación más rápido.",
						"3. Dinos qué hiciste antes de perder los objetos (reinstalar, actualizar o ejecutar el juego en nuevos dispositivos).",
					]
				},
				{
					"issueTitle": "Quiero que me devuelvan el dinero.",
					"issueAnswerType": 1,
					"issueAnswer": [
						"A continuación tienes nuestra política de reembolso. Solo se devolverá el dinero cuando se cumplan las siguientes condiciones:",
						"1. Una solicitud de reembolso se acepta solo cuando las gemas, monedas o cualquier otro artículo del juego que hayas comprado todavía estén en tu cuenta y no hayan sido utilizados.",
						"2. Si previamente has recibido un reembolso por un artículo comprado, no habrá reembolso por ninguna otra compra que hagas.",
						"3. Puedes recibir un reembolso solo si presentas una solicitud de reembolso en los 7 días posteriores a su compra. Cualquier solicitud de reembolso presentada después de 7 días será rechazada.",
						"4. Si cumples nuestras condiciones de reembolso, ponte en contacto con nosotros y proporciónanos una captura de pantalla o el ID de pedido de la transacción, y tu ID de jugador. Nuestra dirección de correo electrónico es: punball@habby.com. Verificaremos la solicitud de reembolso una vez recibido el mensaje y los aplicaremos una vez aprobado. El plazo de entrega es de 2 a 3 días laborables. Por favor, ten paciencia y no nos envíes el mismo mensaje varias veces. Gracias por tu cooperación.Nota: Si tu cuenta o dispositivo han sido usados por otros, o si has permitido que otros hagan compras en tu cuenta, no asumimos ninguna responsabilidad por la compras realizadas en tu cuenta. En dicho caso, no realizaremos ningún reembolso. Gracias."
					]
				},
				{
					"issueTitle": "¿Cómo evito compras accidentales?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Punball es un juego que se puede descargar gratis en todo el mundo, pero se pueden comprar paquetes y gemas en la tienda del juego. Si necesitas evitar compras accidentales, cambia los ajustes de tu dispositivo.Dispositivos iOS:",
						"1. Ve a Ajustes > General > Restricciones.",
						"2. Selecciona Habilitar restricciones.",
						"3. Introduce una contraseña. Se recomienda establecer una contraseña diferente de la contraseña para desbloquear el dispositivo.",
						"4. Cuando habilites Restricciones, verás una lista de acciones que tus aplicaciones pueden realizar. Para evitar compras en la aplicación, por favor desactiva la opción correspondiente.",
						"Si no quieres evitar por completo las compras en la aplicación, puedes cambiar la configuración de la contraseña de tus aplicaciones. Cuando deshabilites Restricciones, ve a Ajustes > App Store > Ajustes de contraseña.",
						"Solo con iOS 12.3 o superior:",
						"1. Ve a Ajustes > Tiempo de pantalla > Restricciones de contenido y privacidad.",
						"2. Activa Restricciones de contenido y privacidad.",
						"3. Toca Compras en iTunes y App Store.",
						"4. Toca Requerir siempre o toca Compras en la aplicación > No permitir.",
						"Dispositivos Android:",
						"1. Abre Play Store.",
						"2. Busca Ajustes.",
						"3. Toca Requerir autenticación para compras.",
						"4. Selecciona una opción excepto Nunca.",
						"Ahora todas las compras en la aplicación a través de Google Play requieren autenticación.",
						"Nota: Si seleccionas \"Cada 30 minutos\", cada vez que introduces tu contraseña y completas una transacción, no se requerirá contraseña para las compras realizadas en los siguientes 30 minutos."
					]
				}
			]
		},
		{
			"title": "Problemas de red",
			"list": [
				{
					"issueTitle": "Fallo de energía.",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Revisa lo siguiente:",
						"1. Comprueba tu red y si el dispositivo está conectado.",
						"2. Comprueba si estás ejecutando el juego en varios dispositivos y si has usado energía en esos dispositivos.",
						"3. Comprueba si has cambiado la hora local de tu dispositivo. No nos ocupamos de ningún problema de energía derivado de un cambio de hora.",
						"Si el error persiste, ponte en contacto con nosotros e indícanos:",
						"1. Los valores específicos de la energía real y las fluctuaciones de energía.",
						"2. Más específicamente, ¿cuándo se produjeron las fluctuaciones de energía?",
						"3. Tu ID de jugador.",
						"Te ofreceremos algún tipo de compensación después de recibir y verificar el problema. El plazo de entrega es de 2 a 3 días laborables. Por favor, ten paciencia y no nos envíes el mismo mensaje varias veces. Gracias por tu cooperación."
					]
				},
				{
					"issueTitle": "Problema con los anuncios.",
					"issueAnswerType": 1,
					"issueAnswer": [
						"No se pueden ver los anuncios:",
						"Revisa lo siguiente:",
						"1. Asegúrate de que la red de tu teléfono es normal. Puedes probar a cambiar entre 4G y wifi.",
						"2. Puesto que se tarda un tiempo en cargar un anuncio, por favor no toques un anuncio inmediatamente después de entrar en el juego. Juega durante un tiempo cuando la conexión de red sea buena y luego vuelve a ver el anuncio.",
						"Si esto no soluciona el problema, ponte en contacto con nosotros e indícanos tu ID de jugador y dónde se encuentra el anuncio que no se ha cargado.",
						"Aplicaciones inadecuadas:",
						"Para cualquier anuncio inadecuado (por ejemplo, violencia o juegos de azar) en el juego, por favor envía un mensaje correo electrónico a punball@habby.com con la siguiente información:",
						"1. Por favor, adjunta una captura de pantalla del contenido inadecuado en el anuncio o un vídeo del anuncio.",
						"2. Una captura de pantalla o grabación de pantalla de la página del patrocinador a la que redirige el anuncio.",
						"3. Tu ID de jugador. Después de la verificación, te recompensaremos con algunas gemas."
					]
				}
			]
		},
		{
			title: "Contactanos"
		}
	],
	"feedSubmit": {
		"contactUS": "Contacto",
		"contentPlaceholder": "Describe el problema",
		"emailPlaceholder": "Deja tu dirección de correo electrónico para que podamos ponernos en contacto contigo:",
		"submit": "Enviar",
		"success": "Tu mensaje se envió correctamente. Gracias por tus comentarios.",
		"returnToGame": "Volver al juego"
	}
}