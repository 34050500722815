"use strict";

// 俄罗斯
export default {
	"lang": "ru",
	"backGame": "возвращение",
	"title": "Вопрос выбора",
	"issues": [
		{
			"title": "Проблемы с сохранениями",
			"list": [
				{
					"issueTitle": "Как сохранить мой прогресс в игре?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Вы можете войти в Punball с учетной записью Game Center или Google Play Игры.",
						"<b>Вход в качестве гостя</b>：вы войдете в игру в качестве гостя, если заходите в нее первый раз и не вошли в свою учетную запись Game Center (iOS) или Google Play Игры (Android). Сохраненные данные учетной записи гостя привязаны к вашему телефону, так что вы не потеряете сохранение, если удалите и переустановите игру. Однако на другие устройства сохраненные данные гостя перенести нельзя. Обратите внимание, что вы можете потерять сохраненные данные, если выполните жесткую перезагрузку, перепрошивку или джейлбрейк своего устройства и т. п. Мы советуем зайти в учетную запись Game Center (iOS) или Google Play Игры (Android), чтобы не потерять гостевые сохраненные данные.",
						"<b>Вход в учетную запись</b>：если вы войдете в свою учетную запись Game Center (iOS) или Google Play Игры (Android) до того, как впервые зайдете в игру, то по умолчанию используете в игре эту учетную запись. Ваш игровой прогресс будет сохраняться в учетной записи Game Center (iOS) или Google Play Игры (Android) автоматически."
					],
				}, 
				{
					"issueTitle": "Я хочу перенести сохранение на другое устройство.",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Вы можете перенести сохранение на другое устройство при помощи учетной записи Game Center (iOS) или Google Play Игры (Android). В настоящее время сохранения можно переносить только между устройствами с одним типом ОС — с iOS на iOS и с Android на Android.",
						"Как это сделать: убедитесь, что зашли в учетную запись Game Center (iOS) или Google Play Игры (Android) на старом устройстве. Зайдите в ту же учетную запись Game Center (iOS) или Google Play Игры (Android) на новом устройстве и загрузите игру. Когда вы ее запустите, она обнаружит вашу учетную запись и загрузит данные сохранения. (Пользователи Android должны запустить игру через Google Play Игры.)"
					]
				},
				{
					"issueTitle": "Я хочу начать новую игру.",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>Я хочу оставить имеющееся сохранение</b>：убедитесь, что вы зашли в учетную запись Game Center (iOS) или Google Play Игры (Android). Если это так, то связанные с вашей учетной записью данные автоматически сохраняются на сервере. Теперь выйдите из вашей учетной записи, удалите игру и загрузите ее заново. Зайдите в новую учетную запись (ни разу не использовавшуюся для игры в Punball), чтобы создать новое сохранение. Чтобы продолжить играть со старой учетной записью, переключитесь на нее в Game Center (iOS) или Google Play Игры (Android), затем вновь загрузите и запустите игру. Система определит и загрузит ваше предыдущее сохранение.",
						"<b>Мне больше не нужно имеющееся сохранение</b>：зайдите в раздел «Свяжитесь с нами» в игровых настройках и сообщите, что хотите удалить сохранение и начать новую игру. Мы удалим ваше сохранение в течение 1–2 рабочих дней после получения сообщения. После удаления восстановить сохранение будет нельзя, так что вы должны быть уверены в своем решении. Мы не рекомендуем удалять сохранения, поскольку вы развиваетесь по мере игры. Начинать новую игру — не самая удачная мысль."
					]
				},
				{
					"issueTitle": "У меня потерялся весь прогресс.",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"Каждый день мы блокируем учетные записи тех, кто использует читы, и удаляем их сохранения. Нам жаль, если ваше сохранение было удалено. Мы надеемся, что все будут загружать игру через официальные каналы и не станут использовать читы — так что все игроки будут в равном положении.",
						"<b>У меня потерялся весь прогресс, но никаких читов у меня не использовалось.</b>：",
						"<b>Гостевая учетная запись</b>：возможно, вы зашли в учетную запись Game Center (iOS) или Google Play Игры (Android), связанную с другим сохранением. Чтобы вернуться к учетной записи гостя, выйдите из вашей учетной записи, удалите игру и загрузите ее заново. (Мы советуем зайти в новую учетную запись, чтобы не потерять сохраненные данные.)",
						"<b>Я в своей учетной записи, но у меня потерялся весь прогресс</b>：убедитесь, что не поменяли учетную запись. Если вы переключились на другую учетную запись в Game Center (iOS) или Google Play Игры (Android), то вернитесь к предыдущей в настройках телефона.",
						"<b>Если вы не можете вернуть свою учетную запись</b>：пожалуйста, свяжитесь с нами и сообщите：",
						"1. ID пользователя предыдущей учетной записи.",
						"2. ID вашей текущей учетной записи.",
						"3. Ваш адрес электронной почты.",
						"4. Что случилось перед тем, как вы потеряли учетную запись.",
						"Если вы совершали в предыдущей учетной записи какие-либо покупки и не можете их вернуть, мы можем перенести все купленные предметы из старой учетной записи в новую. Вам потребуется предоставить следующие сведения:",
						"1. ID пользователя новой учетной записи.",
						"2. Что за предметы вы купили и в каком количестве.",
						"3. Все скриншоты или ID заказов для транзакций. (Принимаются только квитанции Apple или Google. Пожалуйста, не присылайте нам другие платежные данные, такие как сведения о карте, с которой была произведена оплата, или тексты сообщений о покупках.)Пожалуйста, отправьте эти данные на электронную почту: punball@habby.com. После проверки мы вернем все купленные предметы на вашу учетную запись."
					]
				},
				{
					"issueTitle": "Я могу играть на нескольких устройствах?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>Можете, но так делать не рекомендуется</b>：чтобы синхронизировать игровой прогресс на нескольких устройствах, просто зайдите на них в одну и ту же учетную запись Game Center (iOS) или Google Play (Android). Но мы не советуем так делать. Если на ваших устройствах используется одно сохранение, то в том случае, если вы открыли бесплатный сундук на устройстве A, а затем открываете его снова на устройстве B, вы потеряете камни, поскольку сундук уже был открыт на устройстве А. Чтобы не потерять случайно камни, мы не советуем играть одновременно на нескольких устройствах."
					]
				}
			]
		}, 
		{
			"title": "Проблемы с покупками",
			"list": [
				{
					"issueTitle": "Почему я не вижу купленных предметов?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Пожалуйста, проверьте соединение с сетью. Выйдите из игры, подождите немного и перезапустите ее.",
						"Если вы уверены, что не получили купленный предмет, обратитесь к нам и предоставьте следующую информацию:",
						"1.<b>Ваш ID игрока</b>(в настройках игры)",
						"2.<b>Что за предметы вы купили и в каком количестве.</b>",
						"3.<b>Скриншоты или ID заказов для транзакций.</b>(Принимаются только квитанции Apple или Google. Пожалуйста, не присылайте нам другие платежные данные, такие как сведения о карте, с которой была произведена оплата, или тексты сообщений о покупках.)Пожалуйста, отправьте эти данные на электронную почту: punball@habby.com.После получения и проверки вашей информации мы вышлем вам ваш набор. Обычно на это требуется 2–3 рабочих дня. Пожалуйста, наберитесь терпения и не отправляйте нам одно и то же сообщение несколько раз. Благодарим за сотрудничество."
					]
				},
				{
					"issueTitle": "Ошибка в количестве камней или монет.",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Если вы заметили ошибку в количестве ваших камней или монет, то первым делом перезапустите приложение и проверьте соединение с сетью. Проверьте, не зашли ли вы в игру с нескольких устройств, и не тратили ли камни или монеты на этих устройствах.",
						"Если вы уверены, что у вас неправильное количество камней или монет, обратитесь к нам и предоставьте следующую информацию:",
						"1. Ваш ID игрока и количество потерянных камней или монет.",
						"2. Отправьте скриншот или запись экрана на почту punball@habby.com. Это поможет нам быстрее определить проблему и выслать вам компенсацию. ",
						"3. Расскажите, что вы делали до того, как потеряли предметы (переустанавливали игру, обновляли или запускали на новом устройстве).",
					]
				},
				{
					"issueTitle": "Я хочу вернуть деньги за покупку.",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Ниже приведена наша политика возвратов. Мы можем вернуть вам деньги при выполнении следующих условий:",
						"1. Заявка на возврат принимается только в том случае, если камни, монеты или другие купленные игровые предметы не использовались и находятся у вас на учетной записи.",
						"2. Если вы уже возвращали деньги за купленный предмет, за последующие покупки деньги не возвращаются.",
						"3. Вы можете вернуть средства, только если подали запрос в течение 7 дней после покупки. Запросы, поданные позже, чем через 7 дней, будут отклонены.",
						"4. Если условия возврата соблюдаются, свяжитесь с нами и предоставьте скриншот или ID заказа для транзакции, а также ваш ID игрока в игре. Наш адрес электронной почты: punball@habby.com. Мы проверим ваш запрос после получения письма и вернем средства, когда он будет одобрен. Обычно на это требуется 2–3 рабочих дня. Пожалуйста, наберитесь терпения и не отправляйте нам одно и то же сообщение несколько раз. Благодарим за сотрудничество.Примечание: если вашу учетную запись или устройство использовали другие люди, или вы позволяли другим делать покупки с вашей учетной записи, то за такие покупки мы ответственности не несем. В таком случае возврат средств невозможен. Спасибо за понимание."
					]
				},
				{
					"issueTitle": "Как избежать случайных покупок?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Punball — это игра, в которую вы можете бесплатно играть по всему миру, но в игровом магазине можно покупать наборы и камни. Если вы хотите гарантированно избежать случайных покупок, пожалуйста, отключите их в настройках устройства.Устройства iOS:",
						"1. Перейдите в раздел «Настройки > Основные > Ограничения».",
						"2. Выберите «Включить ограничения».",
						"3. Введите код. Мы рекомендуем использовать код, отличный от кода разблокировки устройства.",
						"4. Когда вы включите ограничения, появится список действий, который разрешено выполнять приложениям. Чтобы отключить покупки в приложениях, выключите соответствующую опцию.",
						"Если вы не хотите отключать покупки для всех приложений, то можете установить пароль для приложений. Отключите ограничения и перейдите в раздел «Настройки > App Store > Настройки пароля».",
						"iOS 12.3 и выше:",
						"1. Перейдите в меню «Настройки > Экранное время > Ограничения конфиденциальности и контента».",
						"2. Включите ограничения конфиденциальности и контента.",
						"3. Нажмите «Покупки в iTunes Store и App Store».",
						"4. Выберите опцию «Запрашивать всегда» или нажмите «Встроенные покупки > Не разрешать».",
						"Устройства Android:",
						"1. Откройте «Play Маркет».",
						"2. Перейдите в настройки.",
						"3. Нажмите «Аутентификация при покупке».",
						"4. Выберите любую опцию, кроме «Никогда».",
						"Теперь для всех покупок через Google Play потребуется аутентификация.",
						"Примечание: Если вы выберете опцию «Каждые 30 минут», то после ввода пароля и совершения покупки в следующие 30 минут вводить пароль для покупок не потребуется."
					]
				}
			]
		},
		{
			"title": "Сетевые проблемы",
			"list": [
				{
					"issueTitle": "Ошибка в количестве энергии",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Пожалуйста, проверьте следующее:",
						"1. Убедитесь, что сеть работает, и устройство подключено к сети.",
						"2. Проверьте, не зашли ли вы в игру с нескольких устройств, и не тратили ли энергию на этих устройствах.",
						"1. Убедитесь, что не меняли установленное на устройстве время. Мы не рассматриваем проблемы с энергией, вызванные сменой локального времени на устройстве.",
						"Если ошибка сохраняется, пожалуйста, обратитесь к нам и сообщите:",
						"1. Ваше текущее значение энергии, и сколько энергии не хватает.",
						"2. Когда именно вы недосчитались энергии.",
						"3. Ваш ID игрока.",
						"Проверив ваш случай, мы предложим вам компенсацию. Обычно на это требуется 2–3 рабочих дня. Пожалуйста, наберитесь терпения и не отправляйте нам одно и то же сообщение несколько раз. Благодарим за сотрудничество."
					]
				},
				{
					"issueTitle": "Проблемы с рекламой",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Реклама не показывается:",
						"Пожалуйста, проверьте следующее:",
						"1. Убедитесь, что телефон подключен к работающей сети. Возможно, нужно переключиться на 4G или WiFi.",
						"2. На загрузку рекламы требуется время, так что не нажимайте на эту функцию немедленно после входа в игру. Поиграйте немного, когда у вас хорошее соединение с сетью, а потом снова попробуйте посмотреть рекламу.",
						"Если это не помогает, обратитесь к нам и сообщите свой ID игрока и где не смогла загрузиться реклама.",
						"Неподобающая реклама:",
						"Если вам встретилась неподобающая реклама (например, насилие или азартные игры), напишите нам на punball@habby.com и предоставьте следующие сведения:",
						"1. Приложите скриншот неподобающего содержимого рекламы или запись рекламы.",
						"2. Скриншот или запись экрана на странице спонсора, на которую вела реклама.",
						"3. Ваш ID игрока. После проверки мы вышлем вам в награду камни."
					]
				}
			]
		},
		{
			title: "Свяжитесь с нами"
		}
	],
	"feedSubmit": {
		"contactUS": "Связь с нами",
		"contentPlaceholder": "Пожалуйста, опишите проблему",
		"emailPlaceholder": "Пожалуйста, оставьте свою почту, чтобы мы могли ответить вам:",
		"submit": "Отправить",
		"success": "Ваше сообщение успешно отправлено. Спасибо за отзыв.",
		"returnToGame": "Вернуться в игру"
	}
}