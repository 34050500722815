"use strict";

// 印尼语
export default {
	"backGame": "Kembali",
	"title": "Pilih masalah",
	"issues": [
		{
			"title": "Simpan masalah",
			"list": [
				{
					"issueTitle": "Bagaimana cara menyimpan kemajuan game?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Kamu bisa masuk ke Punball dengan akun Game Center atau Play Games.",
						"<b>Masuk sebagai pengunjung</b>：Kamu akan masuk ke dalam game sebagai pengunjung jika ini adalah permainan pertama kamu dan kamu tidak masuk ke akun Game Center (iOS) atau Play Games (Android) kamu. Data simpanan akun pengunjung kamu diasosiasikan dengan ponsel, jadi kamu tidak akan kehilangan simpanan kamu setelah menghapus atau mengunduh ulang game. Tetapi data simpanan akun pengunjung kamu tidak bisa ditransfer ke perangkat lain. Mohon diperhatikan bahwa kamu bisa kehilangan data simpanan jika kamu melakukan reset pabrik, melakukan flashing atau jailbreaking pada ponsel, dll. Kami menyarankan kamu untuk masuk ke akun Game Center (iOS) atau Play Games (Android) agar simpanan akun pengunjung kamu tidak hilang.",
						"<b>Masuk Akun</b>：Jika kamu masuk ke akun Game Center (iOS) atau Play Games (Android) sebelum permainan pertama, kamu akan memasuki game dengan akunmu secara default. Kemajuan game kamu akan secara otomatis disimpan ke akun Game Center (iOS) atau Play Games (Android)."
					],
				}, 
				{
					"issueTitle": "Saya ingin mentransfer simpanan saya ke perangkat lain.",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Kamu bisa mentransfer simpanan kamu ke perangkat lain melalui akun Game Center (iOS) atau Play Games (Android). Tetapi untuk saat ini, data simpanan hanya bisa ditransfer ke perangkat dengan OS yang sama. Sebagai contoh, iOS ke iOS, Android ke Android.",
						"Metode: Pastikan kamu telah masuk ke akun Game Center (iOS) atau Play Games (Android) di perangkat lama kamu. Masuklah ke akun Game Center (iOS) atau Play Games (Android) yang sama di perangkat baru, lalu unduhlah game. Ketika kamu menjalankan game, perangkat akan mendeteksi akunmu dan mengambil data simpanan. (Pengguna Android harus menjalankan game di Play Games.)"
					]
				},
				{
					"issueTitle": "Saya ingin memulai game baru.",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>Saya ingin menyimpan simpanan saya saat ini</b>：Pastikan kamu telah masuk ke akun Game Center (iOS) atau Play Games (Android). Data simpanan yang diasosiasikan dengan akunmu, jika ada, secara otomatis disimpan ke server kami. Sekarang, keluar dari akun, hapus dan unduh ulang game. Kemudian, masuk ke akun baru (yang belum pernah digunakan untuk masuk ke Punball) untuk membuat simpanan baru. Untuk bermain di akun lama, kembali ke akun Game Center (iOS) atau Play Games (Android) kamu sebelumnya, unduh ulang dan jalankan game. Sistem akan mendeteksi dan memuat simpanan kamu sebelumnya.",
						"<b>Saya tidak menginginkan simpanan saya lagi</b>：Buka “Hubungi Kami” di pengaturan game untuk memberi tahu kami bahwa kamu ingin menghapus simpanan kamu dan memulai game baru. Kami akan menghapus simpananmu dalam 1-2 hari kerja setelah menerima pesan darimu. Data simpanan tidak bisa dipulihkan setelah dihapus, jadi pertimbangkan baik-baik. Kami tidak menyarankan kamu untuk menghapus simpanan, karena kamu akan makin cakap setelah bermain lebih sering. Memulai game baru bukanlah ide yang bagus."
					]
				},
				{
					"issueTitle": "Kemajuan game saya hilang.",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"Kami melarang akun pemain yang curang dan menghapus simpanan mereka setiap hari. Mohon maaf jika simpananmu dihapus. Kami berharap semua orang mengunduh game dari saluran yang benar dan tidak menggunakan cara curang agar kamu dan semua orang lainnya mendapatkan pengalaman bermain yang mengasyikkan.",
						"<b>Saya tidak curang, tetapi kemajuan game saya hilang</b>：",
						"<b>Akun pengunjung</b>：Kamu mungkin masuk ke akun Game Center (iOS) atau Play Games (Android) yang diasosiasikan dengan simpanan lain. Untuk kembali ke simpanan akun pengunjung, keluar dari akun, hapus dan unduh ulang game. (Kami menyarankan kamu untuk masuk ke akun baru untuk menghindari kehilangan simpanan.)",
						"<b>Saya sudah masuk ke akun saya, tetapi simpanan saya hilang</b>：Periksa apakah kamu beralih akun. Jika kamu telah beralih ke akun Game Center (iOS) atau Play Games (Android) lainnya, kembalilah ke akun sebelumnya dengan membuka pengaturan ponsel.",
						"<b>Jika kamu tidak bisa memulihkan akun kamu</b>：Jangan ragu menghubungi kami kapan pun, dan sebutkan:",
						"1. ID pengguna akun kamu sebelumnya",
						"2. Akun kamu saat ini",
						"3. Alamat email kamu",
						"4. Hal yang terjadi sebelum kamu kehilangan akun",
						"Jika kamu telah melakukan pembelian dalam aplikasi di akun sebelumnya dan tidak bisa memulihkannya, kami bisa mentransfer semua barang pembelianmu di akun sebelumnya ke akun baru. Kamu perlu memberikan informasi di bawah kepada kami:",
						"1. ID pengguna akun baru kamu",
						"2. Barang yang kamu beli dan jumlahnya",
						"3. Semua tangkapan layar atau ID pesanan dari transaksimu (Kami hanya menerima tagihan Apple atau Google. Mohon jangan memberikan info pembayaran lainnya, seperti info kartu pembayaran atau teks pembayaran.)Kirimkan informasi di atas ke email kami: punball@habby.com. Setelah verifikasi, kami akan mengembalikan semua barang yang kamu beli ke akun baru kamu."
					]
				},
				{
					"issueTitle": "Dapatkah saya memainkan game ini di beberapa peranti?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>Ya, kamu bisa, tetapi tidak kami sarankan</b>：Untuk menyinkronkan kemajuan game di beberapa peranti, cukup masuk ke akun Game Center (iOS) atau Google Play (Android) yang sama di perantimu. Tetapi kami tidak menyarankan kamu melakukannya. Ketika peranti-peranti kamu berbagi simpanan yang sama, jika kamu membuka sebuah peti gratis di peranti A dan membukanya lagi di peranti B, permata kamu akan dikurangi karena kamu telah membuka peti tersebut di peranti A. Agar terhindar dari kehilangan permata secara tidak sengaja, kami tidak menyarankan memainkan game secara bersamaan di beberapa peranti."
					]
				}
			]
		}, 
		{
			"title": "Masalah pembelian",
			"list": [
				{
					"issueTitle": "Mengapa saya tidak menerima barang yang saya beli?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Periksa koneksi jaringan kamu. Keluar dari game dan mulai ulang kemudian.",
						"Jika kamu yakin tidak menerima barang tersebut, hubungi kami dan berikan informasi berikut:",
						"1.<b> ID pemain kamu</b> (di pengaturan game)",
						"2.<b>Barang yang kamu beli dan jumlahnya</b>",
						"3.<b>Tangkapan layar atau ID pesanan dari transaksimu</b>(Kami hanya menerima tagihan Apple atau Google. Mohon jangan memberikan info pembayaran lainnya, seperti info kartu pembayaran atau teks pembayaran.)Kirimkan informasi di atas ke email kami: punball@habby.com.Kami akan mengirimkan pak tersebut kepadamu setelah menerima dan memverifikasi informasi darimu. Waktu yang diperlukan sekitar 2-3 hari kerja. Mohon bersabar dan jangan mengirimkan pesan yang berulang. Terima kasih atas kerja samanya."
					]
				},
				{
					"issueTitle": "Kesalahan dalam jumlah permata dan koin",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Jika kamu menemukan kesalahan jumlah pada koin atau permata, mohon mulai ulang aplikasi terlebih dahulu dan periksa koneksimu. Periksa apakah kamu masuk ke dalam game di beberapa peranti dan apakah kamu telah menggunakan koin atau permata di peranti tersebut.",
						"Jika kamu yakin ada kesalahan jumlah pada koin atau permata, hubungi kami dan berikan informasi berikut:",
						"1. ID pemain kamu serta jumlah koin atau permata yang hilang.",
						"2. Kirimkan tangkapan layar atau rekaman layar ke punball@habby.com. Hal ini akan membantu kami menemukan masalah dan memberikan kompensasi kepadamu dengan lebih cepat.",
						"3. Beri tahu kami hal yang kamu lakukan sebelum kehilangan barang tersebut (menginstal ulang, memperbarui, atau menjalankan game di peranti baru).",
					]
				},
				{
					"issueTitle": "Saya ingin mendapatkan pengembalian dana.",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Di bawah ini adalah kebijakan pengembalian dana kami. Pengembalian dana akan diberikan hanya ketika persyaratan di bawah terpenuhi:",
						"1. Permintaan pengembalian dana hanya diterima ketika permata, koin, atau barang dalam game yang kamu beli masih ada di akunmu dan belum dipakai sama sekali.",
						"2. Jika sebelumnya kamu telah mendapatkan pengembalian dana untuk barang yang telah dibeli, pembelian lainnya yang kamu lakukan tidak akan dikembalikan dana.",
						"3. Kamu akan menerima pengembalian dana hanya jika kamu mengirimkan permintaan pengembalian dana dalam waktu 7 hari sejak pembelian. Permintaan pengembalian dana yang dikirimkan setelah 7 hari akan ditolak.",
						"4. Jika kamu memenuhi persyaratan pengembalian dana kami, mohon hubungi kami dan berikan tangkapan layar atau ID pesanan dari transaksimu, dan ID pemain kamu dalam game. Alamat email kami: punball@habby.com. Kami akan memverifikasi permintaan pengembalian dana kamu setelah menerima email, dan memberikan pengembalian dana setelah permintaan tersebut disetujui. Waktu yang diperlukan sekitar 2-3 hari kerja. Mohon bersabar dan jangan mengirimkan pesan yang berulang. Terima kasih atas kerja samanya.Catatan: Jika akun atau peranti kamu digunakan oleh orang lain, atau jika kamu membiarkan orang lain melakukan pembelian di akun kamu, kami tidak bertanggung jawab atas pembelian yang dilakukan di akunmu. Bagaimanapun, pengembalian dana tidak akan diberikan. Terima kasih."
					]
				},
				{
					"issueTitle": "Bagaimana cara menghindari pembelian tidak disengaja?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Punball adalah game yang bisa diunduh secara gratis di seluruh dunia, namun pak dan permata bisa dibeli di toko dalam game. Jika kamu ingin menghindari pembelian tidak disengaja, ubah pengaturan di peranti kamu.Peranti iOS:",
						"1. Buka Pengaturan > Umum > Pembatasan.",
						"2. Pilih Aktifkan Pembatasan.",
						"3. Masukkan kode akses. Disarankan untuk mengatur kode akses yang berbeda dari kode akses untuk membuka kunci perantimu.",
						"4. Setelah mengaktifkan Pembatasan, kamu akan melihat daftar tindakan yang dapat dilakukan oleh aplikasi. Untuk mencegah pembelian dalam aplikasi, nonaktifkan opsi terkait.",
						"Jika kamu tidak ingin mencegah pembelian dalam aplikasi sepenuhnya, kamu bisa mengubah pengaturan kata sandi aplikasimu. Setelah menonaktifkan Pembatasan, buka Pengaturan > App Store > Pengaturan Kata Sandi.",
						"iOS 12.3 dan yang lebih baru:",
						"1. Buka Pengaturan > Waktu Layar > Pembatasan Konten & Privasi.",
						"2. Nyalakan Pembatasan Konten & Privasi.",
						"3. Ketuk Pembelian iTunes dan App Store.",
						"4. Ketuk Selalu Perlu, atau ketuk Pembelian Dalam Aplikasi > Jangan Izinkan.",
						"Peranti Android:",
						"1. Buka Play Store.",
						"2. Temukan Pengaturan.",
						"3. Ketuk Wajibkan autentikasi pembelian.",
						"4. Pilih sebuah opsi kecuali \"Tidak pernah\".",
						"Semua pembelian dalam aplikasi melalui Google Play sekarang memerlukan autentikasi.",
						"Catatan: Jika kamu memilih \"Setiap 30 menit\", setiap kali kamu memasukkan kata sandi dan menyelesaikan transaksi, kamu tidak perlu lagi memasukkan kata sandi untuk semua pembelian yang dilakukan dalam 30 menit berikutnya."
					]
				}
			]
		},
		{
			"title": "Masalah jaringan",
			"list": [
				{
					"issueTitle": "Kesalahan energi",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Periksa yang berikut:",
						"1. Periksa jaringanmu dan apakah perantimu terhubung.",
						"2. Periksa apakah kamu menjalankan game di beberapa peranti dan telah menggunakan Energi di peranti-peranti tersebut.",
						"3. Periksa apakah kamu telah mengganti waktu lokal di perantimu. Kami tidak menangani masalah energi yang disebabkan oleh perubahan waktu.",
						"Jika masalah berlanjut, hubungi kami dan sampaikan:",
						"1. Nilai yang spesifik dari Energi kamu sebenarnya dan fluktuasi Energi.",
						"2. Tepatnya kapan fluktuasi Energi ini terjadi?",
						"3. ID pemain kamu.",
						"Kami akan memberikan kompensasi setelah memverifikasi masalahmu. Waktu yang diperlukan sekitar 2-3 hari kerja. Mohon bersabar dan jangan mengirimkan pesan yang berulang. Terima kasih atas kerja samanya."
					]
				},
				{
					"issueTitle": "Masalah dengan iklan",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Tidak bisa menonton iklan:",
						"Periksa yang berikut:",
						"1. Pastikan jaringan ponselmu normal. Kamu mungkin bisa mencoba beralih ke 4G/wifi.",
						"2. Karena pemuatan iklan memerlukan waktu, mohon jangan langsung mengetuk iklan setelah memasuki game. Mohon mainkan game sebentar ketika koneksi jaringan baik, lalu tonton iklan lagi.",
						"Jika hal ini tidak menyelesaikan masalah, hubungi kami dan beri tahukan ID pemain kamu dan tempat iklan yang gagal dimuat.",
						"Aplikasi tidak pantas:",
						"Untuk iklan yang tidak pantas (mis. kekerasan atau perjudian) dalam game, kirimkan email ke punball@habby.com beserta informasi berikut:",
						"1. Lampirkan tangkapan layar dari konten tidak pantas dalam iklan atau rekaman video dari iklan tersebut.",
						"2. Tangkapan layar atau rekaman layar dari halaman sponsor yang diarahkan oleh iklan tersebut.",
						"3. ID pemain kamu. Setelah verifikasi, kami akan memberikan imbalan permata untukmu."
					]
				}
			]
		},
		{
			title: "Hubungi kami"
		}
	],
	"feedSubmit": {
		"contactUS": "Hubungi Kami",
		"contentPlaceholder": "Jelaskan masalahmu",
		"emailPlaceholder": "Cantumkan emailmu agar kami bisa menghubungimu:",
		"submit": "Kirim",
		"success": "Pesanmu berhasil terkirim. Terima kasih atas masukanmu.",
		"returnToGame": "Kembali ke game"
	}
}