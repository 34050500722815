"use strict";

// 英语
export default {
	"backGame": "Back",
	"title": "Select Issue",
	"issues": [
		{
			"title": "Save issues",
			"list": [
				{
					"issueTitle": "How do I save the game progress?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"You can enter Punball with a Game Center or Play Games account.",
						"<b>Log in as a visitor</b>：You will enter the game as a visitor if it is your first gameplay and you did not log into your Game Center (iOS) or Play Games (Android) account. The save data of your visitor account is associated with your phone, so you will not lose your save after deleting and re-downloading the game. But your visitor account’s save data cannot be transferred to other devices. Please note that you may lose your save data when performing a factory reset, flashing or jailbreaking your phone etc. We suggest logging into your Game Center (iOS) or Play Games (Android) account to avoid losing your visitor account’s save.",
						"<b>Account Login</b>：If you log into your Game Center (iOS) or Play Games (Android) account before your first gameplay, you will enter the game with your account by default. Your game progress will be automatically saved to your Game Center (iOS) or Play Games (Android) account."
					],
				}, 
				{
					"issueTitle": "I want to transfer my save to other devices.",
					"issueAnswerType": 1,
					"issueAnswer": [
						"You can transfer your save to other devices through your Game Center (iOS) or Play Games (Android) account. But at the moment, save data can only be transferred to devices with the same OS. For instance, iOS to iOS, Android to Android.",
						"Method: Make sure you have logged into your Game Center (iOS) or Play Games (Android) account on your old device. Log into the same Game Center (iOS) or Play Games (Android) account on a new device, and download the game. When you run the game, it will detect your account and retrieve the save data. (Android users need to run the game in Play Games.)"
					]
				},
				{
					"issueTitle": "I want to start a new game.",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>I want to keep my current save</b>：Make sure you have logged into your Game Center (iOS) or Play Games (Android) account. If you have, the save data associated with your account is automatically saved to our server. Now, log out of your account, delete and re-download the game. Then, log into a new account (which has never been used to enter Punball) to create a new save. To play on the old account, switch back to your previous Game Center (iOS) or Play Games (Android) account, re-download and run the game. The system will detect and load your previous save.",
						"<b>I don’t want my save anymore</b>：Go to “Contact Us” in the game settings to tell us you want to delete your save and start a new game. We will delete your save within 1-2 working days upon receipt of your message. Save data cannot be retrieved once deleted, so please think it over. It is not advisable to have your save deleted, since you will get better at the game as you play more. It is not a good idea to start a new game."
					]
				},
				{
					"issueTitle": "I’ve lost my progress.",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"We ban cheaters’ accounts and delete their saves on a daily basis. We are very sorry if your save was deleted. We hope that everybody downloads the game through a proper channel and uses no cheats at all to ensure you and the others have a good gaming experience.",
						"<b>I did not cheat, but I’ve lost my progress</b>：",
						"<b>Visitor account</b>：You may have logged into a Game Center (iOS) or Play Games (Android) account associated with another save. To get back your visitor account’s save, log out of your account, delete and re-download the game. (We suggest logging into a new account to avoid losing your save.)",
						"<b>I’ve logged into my account, but my save was lost</b>：Check if you have switched accounts. If you have switched to another Game Center (iOS) or Play Games (Android) account, switch back to the previous account by going to your phone settings.",
						"<b>If you can’t retrieve your account</b>：Please feel free to contact us anytime, and tell us:",
						"1. The user ID of your previous account",
						"2. Your current account",
						"3. Your e-mail address",
						"4. What happened before you lost your account",
						"If you made in-app purchases on your previous account and cannot retrieve it, we can transfer all of your purchased items on your previous account to the new one. You would need to provide the information below to us:",
						"1. The user ID of your new account",
						"2. What you purchased and the quantity",
						"3. All screenshots or order IDs of your transactions (We only accept Apple or Google bills. Please do not provide other payment info such as the info of your payment cards or payment texts.) Send the information above to our e-mail: punball@habby.com. After verification, we will return all of the items you purchased to your new account."
					]
				},
				{
					"issueTitle": "Can I play the game on multiple devices?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>You can, but it is not advisable</b>：To sync your game progress between multiple devices, just log into the same Game Center (iOS) or Google Play (Android) account on your devices. But we do not suggest doing so. When your devices share the same save, if you open a free chest on device A and open it again on device B, your gems will be deducted because you have already opened the chest on device A. To avoid accidentally losing gems, we advise against playing the game simultaneously on multiple devices."
					]
				}
			]
		}, 
		{
			"title": "Purchase issues",
			"list": [
				{
					"issueTitle": "Why didn’t I receive the item I purchased?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Please check your network connection. Exit the game and restart later.",
						"If you are sure you did not receive the item, please contact us and provide the following information:",
						"1.<b>Your player ID</b>(in the game settings)",
						"2.<b>What you purchased and the quantity</b>",
						"3.<b>Screenshots or order IDs of your transaction</b> (We only accept Apple or Google bills. Please do not provide other payment info such as the info of your payment cards or payment texts.) Please send the information above to our e-mail: punball@habby.com. We will send you a pack after receiving and verifying your information. It will take around 2-3 working days. Please be patient and do not send us repeated messages. Thank you for your cooperation."
					]
				},
				{
					"issueTitle": "Error in the number of gems and coins",
					"issueAnswerType": 1,
					"issueAnswer": [
						"If you notice an error in the number of your coins or gems, please first restart the app and check your connection. Check if you logged into the game on multiple devices and if you used coins or gems on those devices.",
						"If you are sure there is an error in the number of coins or gems, please contact us and provide the following information:",
						"1. Your player ID and the number of coins or gems you lost.",
						"2. Send any screenshot or screen recording to punball@habby.com. It will help us pinpoint the issue and give you compensation quicker.",
						"3. Tell us what you did before losing the items (reinstalling, updating or running the game on new devices).",
					]
				},
				{
					"issueTitle": "I want to get a refund.",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Below is our refund policy. A refund will be given only when the conditions below are met:",
						"1. A refund request is accepted only when the gems, coins or any other in-game items you purchased are still in your account and have not been used at all.",
						"2. If you have previously been given a refund for a purchased item, any other purchases you make will not be refunded.",
						"3. You may be given a refund only if you submit a refund request within 7 days of your purchase. Any refund request submitted after 7 days will be rejected.",
						"4. If you meet our refund conditions, please contact us and provide a screenshot or the order ID of your transaction, and your player ID in the game. Our e-mail address is: punball@habby.com. We will verify your refund request upon receipt of your e-mail, and give you a refund once it is approved. It will take around 2-3 working days. Please be patient and do not send us repeated messages. Thank you for your cooperation.Note: If your account or device was used by others, or if you allowed others to make purchases on your account, we bear no responsibility for the purchases made on your account. In which case, no refund will be given. Thank you."
					]
				},
				{
					"issueTitle": "How do I avoid accidental purchases?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Punball is a game that is free to download worldwide, but packs and gems can be purchased in the in-game shop. If you need to avoid accidental purchases, please change the settings of your device.iOS devices:",
						"1. Go to Settings > General > Restrictions.",
						"2. Select Enable Restrictions.",
						"3. Enter a passcode. It is recommended to set a passcode different from the one for unlocking your device.",
						"4. Once you enable Restrictions, you will see a list of actions that your apps are allowed to perform. To prevent in-app purchases, please toggle off the corresponding option.",
						"If you do not want to prevent in-app purchases completely, you can change the password settings of your apps. Once you disable Restrictions, go to Settings > App Store > Password Settings.",
						"iOS 12.3 and above:",
						"1. Go to Settings > Screen Time > Content & Privacy Restrictions.",
						"2. Turn on Content & Privacy Restrictions.",
						"3. Tap iTunes and App Store Purchases.",
						"4. Tap Always Require, or tap In-app Purchases > Don't Allow.",
						"Android devices:",
						"1. Open Play Store.",
						"2. Find Settings.",
						"3. Tap Require authentication for purchases.",
						"4. Select an option except Never.",
						"All in-app purchases through Google Play now require authentication.",
						"Note: If you select “Every 30 minutes”, every time you enter the password and complete a transaction, no password is required for all purchases made within the next 30 minutes."
					]
				}
			]
		},
		{
			"title": "Network issues",
			"list": [
				{
					"issueTitle": "Energy error",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Please check the following:",
						"1. Check your network and whether your device is connected.",
						"2. Check if you are running the game on multiple devices and used Energy on those devices.",
						"3. Check if you have changed the local time of your device. We do not deal with any energy issue caused by changing the time.",
						"If the error persists, please contact us and tell us:",
						"1. The specific values of your actual Energy and Energy fluctuations.",
						"2. Specifically, when did the Energy fluctuations occur?",
						"3. Your player ID.",
						"We will offer some compensation after verifying your issue. It will take around 2-3 working days. Please be patient and do not send us repeated messages. Thank you for your cooperation."
					]
				},
				{
					"issueTitle": "Issues with ads",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Unable to watch ads:",
						"Please check the following:",
						"1. Ensure the network of your phone is normal. You may try switching to 4G/wifi.",
						"2. As it takes time to load an ad, please do not tap an ad immediately after entering the game. Please play the game for a bit when the network connection is good, then watch the ad again.",
						"If this does not fix the issue, please contact us and tell us your player ID and where the ad that failed to load is.",
						"Inappropriate apps:	",
						"For any inappropriate ad (e.g. violence or gambling) in the game, please send an e-mail to punball@habby.com with the information below:",
						"1. Attach a screenshot of the inappropriate content in the ad or a video of the ad.",
						"2. A screenshot or screen recording of the sponsor’s page that the ad redirected to.",
						"3. Your player ID. After verification, we will reward you with some gems."
					]
				}
			]
		},
		{
			title: "Contact us"
		}
	],
	"feedSubmit": {
		"contactUS": "Contact Us",
		"contentPlaceholder": "Please describe your issue",
		"emailPlaceholder": "Please leave your email so that we can contact you:",
		"submit": "Submit",
		"success": "Your message was sent successfully. Thank you for your feedback.",
		"returnToGame": "Back to game"
	}
}