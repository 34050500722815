"use strict";

import upload from './upload';
import error from './error'
import client from './client';

import issues from './issues';

import "../css/issues.css";

const getImages = upload.getImages;
const sendBrowserError = error.sendBrowserError;
const getUserInfo = client.getUserInfo;


window.onerror = function (error) {
	sendBrowserError(error);
};

function getFormData() {
	const formData = $('#feedback-form').serializeArray();

	const result = {};
	for (let { name, value } of formData) {
		result[name] = value;
	}

	return result;
}

function getPostData() {
	const formData = getFormData();
	const userInfo = getUserInfo();

	const attachments = [];
	let uploadedImages = getImages();
	for (let key in uploadedImages) {
		attachments.push(uploadedImages[key]);
	}

	return Object.assign({}, formData, userInfo, {
		languages: navigator.languages,
		userAgent: navigator.userAgent,
	}, {
		attachments
	});
}


function sendFeedback() {
	const postData = getPostData();

	showLoadingPage();

	$.ajax({
		url: 'api/feedback',
		type: 'POST',
		processData: false,
		contentType: 'application/json',
		data: JSON.stringify(postData),
		dataType: 'json',
		success: function (data) {
			if (data.success) {
				showFinishPage();
			}
		},

		error(xhr, errorType, error) {
			showMainPage();

			sendBrowserError(error, 10200, JSON.stringify(postData));
		}
	});
}

function showLoadingPage() {

	$("#loading").show();

	$("#main").hide();
	$("#finish").hide();
}

function showFinishPage() {
	$("#finish").show();

	$("#finish #theMessageSent").html(issues.LANG.feedSubmit.success);
	if (issues.LANG.feedSubmit.isArabic) {
		$("#finish #theMessageSent").parent().append(
			'<p>' + issues.LANG.feedSubmit.success2 + '</p>'
		);
	} else {
		$("#finish #theMessageSent").css('width', '70%');
	}
	if (issues.LANG.lang === 'ru') {
		$(".finish-button span").css('font-size', '22px');
	}
	$("#finish #back-game").html(issues.LANG.feedSubmit.returnToGame);

	$("#main").hide();
	$("#loading").hide();
}

function showMainPage() {
	$("#main").show();

	$("#finish").hide();
	$("#loading").hide();
}

function validateEmail(email) {
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

function checkForm(event) {
	const messageEle = $("#message");
	const emailEle = $("#email");

	const button = $("#submit-button");
	const emailContent = $(emailEle).val();

	const isMessageNotEmpty = messageEle.val() !== "";
	const isEmail = emailContent && validateEmail(emailContent)
	const isAgree = document.getElementById("isAgree").checked
	const isAgeMatched = document.getElementById("isAgeMatched").checked

	if (issues.isKorea) {
		if (isMessageNotEmpty && isEmail && isAgree && isAgeMatched) {
			button.removeClass('submit-button-forbidden');
		} else {
			button.addClass('submit-button-forbidden');
		}
	} else {
		if (isMessageNotEmpty && isEmail) {
			button.removeClass('submit-button-forbidden');
		} else {
			button.addClass('submit-button-forbidden');
		}
	}
}


$("#close-button").on('click', function () {
	$("#scene-4").hide();
	$("#scene-1").show();
});

$("#back-game").on('click', client.closePage);

$("#message").on('keyup input', checkForm);
$("#email").on('keyup input', checkForm);
$("#isAgree").on('keyup input', checkForm)
$("#isAgeMatched").on('keyup input', checkForm)

$("#submit-button").on('click', function (event) {
	if (!$("#submit-button").hasClass('submit-button-forbidden')) {
		sendFeedback();
	}
});

$("input").blur(function () {
	setTimeout(function () {
		const scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
		window.scrollTo(0, Math.max(scrollHeight - 1, 0));
	}, 100);
});
$("textarea").blur(function () {
	setTimeout(function () {
		const scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
		window.scrollTo(0, Math.max(scrollHeight - 1, 0));
	}, 100);
});

// window.android_back = function () {
// 	if ($("#scene-3").css('display') !== 'none') {
// 		$("#scene-3").hide();
// 		$("#scene-2").show();
// 	} else if ($("#scene-2").css('display') !== 'none') {
// 		$("#scene-2").hide();
// 		$("#scene-1").show();
//
// 		client.isShouldClose(0);
// 	} else if ($("#scene-4").css('display') !== 'none') {
// 		$("#scene-4").hide();
// 		$("#scene-1").show();
//
// 		client.isShouldClose(0);
// 	}
// };

if (/android/.test(navigator.userAgent.toLocaleLowerCase())) {
	$("#main").addClass('android');
}

$("#open-privacy-policy").on('click', function (event) {
	window.open("https://www.habby.com/privacyPolicy.html")
})

if (issues.isKorea) {
	$("#sp1").show();
}

(function () {
	if (issues.LANG.lang === 'ar') {
		$(".scene-3 .issues-container").css('text-align', 'right');
	}
}());