"use strict";

import './libs/zepto';

function sendBrowserError(error, errorCode, info) {
	// $.post('/api/v1/browser_error', {
	// 	errorCode,
	// 	error: JSON.stringify(error),
	// 	info,
	// 	userAgent: navigator.userAgent
	// }, function (data) {
	//
	// }, 'json')
}

export default {
	sendBrowserError: sendBrowserError
}