"use strict";
import ejs from 'ejs'
import client from './client';

import zh from './lang/zh';
import zh2 from './lang/zh2';
import ar from './lang/ar';
import en from './lang/en';
import de from './lang/de';
import fr from './lang/fr';
import id from './lang/id';
import ja from './lang/ja';
import ko from './lang/ko';
import pt from './lang/pt';
import ru from './lang/ru';
import es from './lang/es';


import issueContent from './issue-content';

let LANG = {};

const userLocale =
	navigator.languages && navigator.languages.length
		? navigator.languages[0]
		: navigator.language;

const isKorea = userLocale.toLocaleLowerCase().startsWith("ko");
// const isKorea = true

function initLANG() {
	let language = navigator.language.toLowerCase();
	// let language = "ko";

	if (language.startsWith('zh')) {
		if ('zh-cn' === language) {
			LANG = zh;
		} else {
			LANG = zh2;
		}
	} else if (language.startsWith('ar')) {
		// LANG = ar;
		LANG = en;
	} else if (language.startsWith('ja')) {
		LANG = ja;
	} else if (language.startsWith('ko')) {
		LANG = ko;
	} else if (language.startsWith('ru')) {
		LANG = ru;
	} else if (language.startsWith('pt')) {
		LANG = pt;
	} else if (language.startsWith('fr')) {
		LANG = fr;
	} else if (language.startsWith('de')) {
		LANG = de;
	} else if (language.startsWith('es')) {
		LANG = es;
	} else if (language.startsWith('id')) {
		LANG = id;
	} else {
		LANG = en;
	}
}

function initScene1() {

	const template = `
	<h1><%= title %></h1>
	<ul>
		<% for (var i = 0; i < issues.length; i++) { %>
		<li category-index="<%= i %>"><%= issues[i].title %><span class="more"></span></li>
		<% } %>
	</ul>
`;

	$(".scene-1 .issues-container").html(ejs.render(template, LANG));
}

function initScene2(categoryIndex) {
	let template = `
	<h1><%= title %></h1>
	<ul>
		<% for (var i = 0; i < list.length; i++) { %>
		<li category-index="${categoryIndex}" issue-index="<%= i %>"><%= list[i].issueTitle %><span class="more"></span></li>
		<% } %>
	</ul>
`;
	if (LANG.lang === 'ar') {
		template = `
			<h1><%= title %></h1>
			<ul>
				<% for (var i = 0; i < list.length; i++) { %>
				<li category-index="${categoryIndex}" issue-index="<%= i %>" style="text-align: right; padding-right: 24px;">
					<%= list[i].issueTitle %><span class="more"></span></li>
				<% } %>
			</ul>
		`;
	}

	$(".scene-2 .issues-container").html(ejs.render(template, LANG.issues[categoryIndex]));
}

function initScene3(categoryIndex, issueIndex) {

	const issue = LANG.issues[categoryIndex].list[issueIndex];
	issue.categoryTitle = LANG.issues[categoryIndex].title;

	const content = issueContent.initContent(issue);
	issue.content = content;
	issue.backGame = LANG.backGame;

	const template = `
		<h1><%= categoryTitle %></h1>
		<h3><%= issueTitle%></h3>
		<div class="content content-${issue.issueAnswerType}">
			<%- content%>
		</div>
		<div class="back-to-game" id="back-to-game"><%= backGame %></div>
	`;

	$(".scene-3 .issues-container").html(ejs.render(template, issue));
}

function initFacebook() {
	const value = localStorage.getItem('is_click_facebook');

	if (!value) {
		$('.red').show();
	}
}

initLANG();
initScene1();
initFacebook();

$("#scene-1 li").on('click', function (event) {
	setTimeout(function () {
		const target = $(event.target);
		const index = target.attr('category-index');
		if (index) {
			if (LANG.issues.length - 1 !== parseInt(index, 10)) {
				initScene2(index);

				$("#scene-1").hide();
				$("#scene-2").show();
			} else {
				// 提交反馈
				$("#scene-1").hide();
				$("#scene-4").show();
				$("#scene-4 .title-back").html(LANG.feedSubmit.contactUS);
				$("#scene-4 .title-front").html(LANG.feedSubmit.contactUS);
				$("#scene-4 #message").attr('placeholder', LANG.feedSubmit.contentPlaceholder);
				$("#scene-4 .email-tip").html(LANG.feedSubmit.emailPlaceholder);
				$("#scene-4 .submit-button-forbidden").html(LANG.feedSubmit.submit);

				if (isKorea) {
					$("#is-agree-text").html(LANG.feedSubmit.isAgree)
					$("#open-privacy-policy").html(LANG.feedSubmit.view)
					$("#is-age-matched-text").html(LANG.feedSubmit.isAgeMatched)
					$("#privacy-policy-tip").html(LANG.feedSubmit.tip)
				}
			}
		} else {
			localStorage.setItem('is_click_facebook', 'YES');
			$('.red').hide();

			client.goToFacebook();
		}

	}, 300);
});

$("#scene-2").on('click', 'li', function (event) {
	setTimeout(function () {
		const target = $(event.target);
		const categoryIndex = target.attr('category-index');
		const issueIndex = target.attr('issue-index');
		initScene3(categoryIndex, issueIndex);

		$("#scene-2").hide();
		$("#scene-3").show();
	}, 300);
});

$("#close-button-scene-1").on('click', function () {
	client.closePage();
});

$("#close-button-scene-2").on('click', function () {
	$("#scene-2").hide();
	$("#scene-1").show();
});

$("#close-button-scene-3").on('click', function () {

	$("#scene-3").hide();
	$("#scene-2").show();
});

$(".scene-3").on('click', '.back-to-game', function () {
	setTimeout(function () {
		client.closePage();
	}, 300);
});

export default {
	LANG,
	isKorea,
}