"use strict";

import "./libs/zepto";
import error from './error';
import clientInfo from './client';

const getUserInfo = clientInfo.getUserInfo;
const sendBrowserError = error.sendBrowserError;

const uploadedImages = {};

function loadLocalImages(files) {
	for (let key in files) {
		const file = files[key];
		const fileReader = new FileReader();

		fileReader.onload = (function (key) {
			return function (event) {
				const imageData = event.target.result;

				const imagePreview = $('.image-preview[data-index="' + key + '"]');

				imagePreview.addClass('image-uploading');
				imagePreview.find('.upload-image-stuff').replaceWith('<img src="' + imageData + '" alt=""/>');
			}
		})(key);

		fileReader.readAsDataURL(file);
	}
}

function uploadImages(files) {
	for (let key in files) {
		const file = files[key];

		const userInfo = getUserInfo();
		const formData = new FormData();
		formData.append('attachment', file);
		formData.append('userInfo', JSON.stringify(userInfo));


		$.ajax({
			url: '/api/v1/feedback/attachment',
			type: 'POST',
			processData: false,
			contentType: false,
			data: formData,
			dataType: 'json',
			xhr: function () {
				const myXhr = $.ajaxSettings.xhr();

				if (myXhr.upload) {
					myXhr.upload.addEventListener('progress', (function (key) {
						return function (event) {
							const imagePreview = $('.image-preview[data-index="' + key + '"]');

							if (event.lengthComputable) {
								imagePreview.find('.upload-progress')
									.css('width', 6 + 24 * event.loaded / event.total * 0.8);
							}
						}
					})(key), false);
				}

				return myXhr;
			},

			success: (function (key) {
				return function (data) {
					if (data.data) {
						uploadedImages[key] = data.data.key;
					}

					const imagePreview = $('.image-preview[data-index="' + key + '"]');
					imagePreview.removeClass('image-uploading');
					imagePreview.addClass('image-upload-success');
				}
			})(key),

			error: (function (key) {
				return function (xhr, errorType, error) {
					const imagePreview = $('.image-preview[data-index="' + key + '"]');
					imagePreview.removeClass('image-uploading');
					emptyImage(imagePreview);

					sendBrowserError(error, 10300);
				}
			})(key)
		});
	}
}

function cancelUploadedImage(event) {
	const ele = event.target;

	const imagePreview = $(ele).parent('.image-preview');
	const i = imagePreview.attr('data-index');

	emptyImage(imagePreview);

	delete uploadedImages[i];
}

function emptyImage(imagePreview) {
	imagePreview.removeClass('image-upload-success');
	imagePreview.find('img').replaceWith('<span class="upload-image-stuff"></span>');
	resetProgressBar(imagePreview);
}

function resetProgressBar(imagePreview) {
	imagePreview.find('.upload-progress').css('width', 6);
}

$("#camera").on('click', function (event) {
	$("#attachment").click();
});

$("#attachment").on('change', function (event) {
	const selectedFiles = event.target.files;

	const ele = $(".upload-image-stuff");

	const files = {};
	for (let i = 0; i < ele.length; i++) {
		const index = $(ele[i]).parent('.image-preview').attr('data-index');

		if (selectedFiles[i]) {
			files[index] = selectedFiles[i]
		}
	}

	loadLocalImages(files);
	uploadImages(files);
});

$(".cancel_uploaded_image").on('click', cancelUploadedImage);


export default {
	getImages: function () {
		return uploadedImages;
	}
}
