"use strict";
// 阿拉伯
export default {
	"lang": "ar",
	"backGame": "عودة",
	"title": "سؤال الاختيار",
	"issues": [
		{
			"title": " مشكلات الحفظ",
			"list": [
				{
					"issueTitle": " كيف يمكنني حفظ تقدم اللعبة؟",
					"issueAnswerType": 1,
					"issueAnswer": [
						"يمكنك دخول لعبة Punball باستخدام حساب Game Center أو Play Games.",
						"<b>تسجيل الدخول بصفة زائر: </b>：سوف تدخل إلى اللعبة بصفتك زائرًا إذا كانت هذه تجربة اللعبة الأولى لك وإذا لم تقم بتسجيل الدخول إلى حسابك على Game Center (iOS) أو Play Games (أندرويد). تقترن البيانات المحفوظة من حساب الزائر الخاص بك بجهازك الجوال، لذلك لن تفقد البيانات المحفوظة بعد حذف اللعبة وإعادة تنزيلها. ولكن لا يمكن نقل المعلومات المحفوظة في حساب الزائر الخاص بك إلى أجهزة أخرى. يرجى ملاحظة أنك قد تفقد بياناتك المحفوظة عند إجراء إعادة تعيين على إعدادات المصنع، أو تغيير نظام التشغيل أو كسر حماية الهاتف الجوال. ننصحك بتسجيل الدخول إلى حسابك على Game Center (iOS) أو Play Games (أندرويد) لتجنب فقدان بياناتك المحفوظة على حساب الزائر الخاص بك",

						"<b>تسجيل الدخول إلى الحساب: </b>：إذا سجّلت الدخول إلى حسابك على Game Center (iOS) أو Play Games (أندرويد) قبل أول تجربة لعب لك، فسوف تدخل إلى اللعبة بحسابك بشكل افتراضي. سوف يتم حفظ تقدمك في اللعبة تلقائيًا في حسابك على Game Center (iOS) أو Play Games (أندرويد)."
					],
				}, 
				{
					"issueTitle": "أريد نقل بياناتي المحفوظة إلى أجهزة أخرى.",
					"issueAnswerType": 1,
					"issueAnswer": [
						"يمكنك نقل بياناتك المحفوظة إلى أجهزة أخرى عبر حسابك على Game Center (iOS) أو Play Games (أندرويد). ولكن في الوقت الحالي، لا يمكن نقل البيانات المحفوظة إلا إلى أجهزة بنفس نظام التشغيل. على سبيل المثل، من نظام التشغيل iOS إلى iOS، من نظام التشغيل أندرويد إلى أندرويد.",
						"الطريقة: تأكد من تسجيل الدخول إلى حسابك على Game Center (iOS) أو Play Games (أندرويد) على جهازك القديم. سجّل الدخول إلى نفس حساب Game Center (iOS) أو Play Games (أندرويد) على جهاز جديد وقم بتنزيل اللعبة. حين تقوم بتشغيل اللعبة، سوف تقوم بالكشف عن حسابك واسترجاع البيانات المحفوظة. (يحتاج مستخدمو أندرويد إلى تشغيل اللعبة في Play Games.)"
					]
				},
				{
					"issueTitle": " أريد بدء لعبة جديدة.",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>أريد الاحتفاظ ببياناتي المحفوظة حاليًا:</b>：تأكد من تسجيل الدخول إلى حسابك على Game Center (iOS) أو Play Games (أندرويد). إذا أرسلت طلبًا بالفعل، سوف يتم حفظ البيانات المحفوظة المقترنة بحسابك تلقائيًا في خادمنا. والآن، قم بتسجيل الخروج من حسابك وحذف اللعبة وإعادة تنزيلها. بعد ذلك، قم بتسجيل الدخول إلى حساب جديد (حساب لم تستخدمه أبدًا للدخول إلى لعبة Punball) لإنشاء بيانات محفوظة جديدة. من أجل اللعب باستخدام الحساب القديم، عاود التبديل إلى حساب Game Center (iOS) أو Play Games (أندرويد)، ثم قم بتنزيل اللعبة مجددًا وتشغيلها. سوف يكشف النظام عن بياناتك السابقة المحفوظة ويقوم بتحميلها.",
						"<b>لم أعد أريد بياناتي المحفوظة:</b>：انتقل إلى ”تواصل معنا“ في إعدادات اللعبة لإخبارنا بأنك تود حذف بياناتك المحفوظة وبدء لعبة جديدة. سوف نقوم بحذف بياناتك المحفوظة خلال يوم أو يومين فور استلام رسالتك. لا يمكن استرجاع البيانات المحفوظة بمجرد حذفها، لذا يرجى التفكير في الأمر. لا يُنصح بحذف بياناتك المحفوظة، بما أن طريقة لعبك ستصبح أفضل كلما لعبت أكثر. بدء لعبة جديدة ليست فكرة سديدة."
					]
				},
				{
					"issueTitle": " لقد فقدت تقدمي.",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"نحن نحظر حسابات الغش ونحذف بياناتها المحفوظة يوميًا. نعرب عن أسفنا لأن بياناتك المحفوظة حُذفت. نأمل أن يقوم الجميع بتنزيل اللعبة عبر قناة مناسبة وألا يستخدموا أي طرق غش على الإطلاق لضمان تجربة لعب جيدة لك وللآخرين.",
						"<b>لم أغش، ولكنني فقدت تقدمي:</b>：",
						"<b>حساب الزائر:</b>： ربما تكون قد سجّلت الدخول إلى حساب Game Center (iOS) أو Play Games (أندرويد) مقترنًا ببيانات محفوظة أخرى. للعودة إلى البيانات المحفوظة في حساب الزائر الخاص بك، قم بتسجيل الخروج من حسابك وحذف اللعبة وإعادة تنزيلها. (نقترح عليك تسجيل الدخول إلى حساب جديد لتجنب فقدان بياناتك المحفوظة.)",
						"<b>لقد سجلت الدخول إلى حسابي، ولكنني فقدت بياناتي المحفوظة:</b>： تحقق مما إذا كنت قمت بتبديل الحسابات. إذا قمت بالتبديل إلى حساب Game Center (iOS) أو Play Games (أندرويد)، فعاود التبديل إلى الحساب السابق عن طريق الذهاب إلى إعدادات جهازك الجوال.",
						"<b>إذا لم تتمكن من استرجاع حسابك:</b>： رجاءً لا تترد في التواصل معنا في أي وقت وإخبارنا بالتالي:",
						" معرّف المستخدم لحسابك السابق",
						" حسابك الحالي",
						" عنوان بريدك الإلكتروني",
						" .ماذا حدث قبل خسارة حسابك",
						"إذا أجريت عمليات شراء داخل التطبيق على حسابك السابق ولم تستطع استعادته، يمكننا نقل جميع عناصرك المُشترات على حسابك السابق إلى حسابك الجديد. سوف تحتاج إلى تقديم المعلومات أدناه إلينا:",
						" معرّف المستخدم لحسابك الجديد ",
						" ما قمت بشرائه والكمية ",
						" جميع لقطات الشاشة أو مُعرفات الطلب لمعاملاتك (لا نقبل سوى فواتير Apple أو Google. يرجى عدم تقديم معلومات دفع أخرى مثل معلومات بطاقات الدفع أو نصوص الدفع.)أرسل المعلومات أعلاه إلى بريدنا الإلكتروني: punball@habby.com. بعد عملية التحقق، سوف نُعيد كل العناصر التي اشتريتها إلى حسابك الجديد. "
					]
				},
				{
					"issueTitle": " هل يمكنني لعب اللعبة باستخدام أجهزة متعددة؟",
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>يمكنك ذلك، ولكن لا يُنصح به:</b>：من أجل مزامنة تقدمك في اللعبة بين أجهزة متعددة، فقط قم بتسجيل الدخول إلى حساب Game Center (iOS) أو Google Play (Android) باستخدام أجهزتك. ولكننا لا ننصح بفعل ذلك. حين تتشارك أجهزتك في اللعبة المحفوظة نفسها، إذا فتحت صندوقًا مجانيًا على الجهاز أ ثم فتحته مجددًا على الجهاز ب، فسوف يتم الاقتطاع من جواهرك لأنك فتحت الصندوق بالفعل على الجهاز أ. ولتجنب خسارة جواهرك بطريق الخطأ، ننصحك بعدم لعب اللعبة على عدة أجهزة في آن واحد."
					]
				}
			]
		}, 
		{
			"title": " مشكلات الشراء",
			"list": [
				{
					"issueTitle": " لماذا لم أستلم العنصر الذي اشتريته؟",
					"issueAnswerType": 1,
					"issueAnswer": [
						"يُرجى تفقد اتصال الشبكة لديك. غادر اللعبة وعاود بدء اللعبة لاحقًا.",
						"إذا كنت متأكدًا من عدم تلقّيك للعنصر، يرجى التواصل معنا وتقديم المعلومات التالية إلينا:",
						"<b>معرّف اللاعب الخاص بك </b>(في إعدادات اللعبة) ",
						"<b> ما قمت بشرائه والكمية</b> ",
						"<b>. لقطات الشاشة أو مُعرفات الطلب لمعاملتك</b> (لا نقبل سوى فواتير Apple أو Google. يرجى عدم تقديم معلومات دفع أخرى مثل معلومات بطاقات الدفع أو نصوص الدفع.)يرجى إرسال المعلومات أعلاه إلى بريدنا الإلكتروني: punball@habby.com.سوف نرسل إليك حزمة بعد تلقّي معلوماتك والتحقق منها. وسوف يستغرق الأمر من يومين إلى 3 أيام عمل تقريبًا. يرجى التحلّي بالصبر وعدم إرسال رسائل متكررة. شكرًا لك على تعاونك معنا."
					]
				},
				{
					"issueTitle": " خطأ في عدد الجواهر والعملات",
					"issueAnswerType": 1,
					"issueAnswer": [
						"إذا لاحظت وجود خطأ في عدد عملاتك أو جواهرك، يرجى إعادة بدء التطبيق أولًا والتحقق من اتصالك. تحقّق مما إذا كنت سجّلت الدخول إلى اللعبة باستخدام أجهزة متعددة وإذا كنت استعملت العملات أو الجواهر وأنت تستخدم هذه الأجهزة.",
						"إذا كنت متأكدًا من وجود خطأ في عدد العملات أو الجواهر، يرجى التواصل معنا وتقديم المعلومات التالية إلينا:",
						" معرّف اللاعب الخاص بك وعدد العملات أو الجواهر الذي فقدته. ",
						" أرسل أي لقطة شاشة أو تسجيل شاشة إلى punball@habby.com. مما سيساعدنا في تحديد المشكلة وإعطائك التعويض بسرعة أكبر. ",
						" أخبرنا بما فعلته قبل فقدان العناصر (إعادة التثبيت أم التحديث أم تشغيل اللعبة باستخدام أجهزة جديدة). ",
					]
				},
				{
					"issueTitle": " أود استرداد أموالي.",
					"issueAnswerType": 1,
					"issueAnswer": [
						"ترد أدناه سياسة الاسترداد الخاصة بنا. سوف يتم رد أموالك فقط حين تستوفي الشروط أدناه:",
						" يتم قبول طلب الاسترداد فقط حين تكون الجواهر أو العملات أو أي عناصر أخرى داخل اللعبة والتي قمت بشرائها لا تزال في حسابك ولم يتم استخدامها على الإطلاق. ",
						" إذا تم رد أموالك إليك مسبقًا التي اشتريت بها عنصرًا ، فلن تسترد أموالك عن أي عمليات شراء أخرى تُجريها. ",
						" يمكنك استرداد أموالك فقط إذا أرسلت طلبًا للاسترداد في غضون 7 أيام من عملية شرائك. سوف يتم رفض أي طلب استرداد مُرسل بعد مرور 7 أيام  ",
						" إذا استوفيت شروط الاسترداد، يرجى التواصل معنا وتزويدنا بلقطة شاشة أو معرّف أمر معاملتك، ومعرّف اللاعب الخاص بك في اللعبة. عنوان بريدنا الإلكتروني هو: punball@habby.com. سوف نتحقق من طلبك للاسترداد عند استلام رسالتك عبر البريد الإلكتروني ونرد إليك أموالك بمجرد الموافقة على طلبك. وسوف يستغرق الأمر من يومين إلى 3 أيام عمل تقريبًا. يرجى التحلّي بالصبر وعدم إرسال رسائل متكررة. شكرًا لك على تعاونك معنا.ملاحظة: إذا تم استخدم شخص آخر حسابك أو جهازك، أو إذا سمحت لآخرين بإجراء عمليات شراء على حسابك، فلن نتحمل مسؤولية أي عمليات شراء أُجريت على حسابك. في هذه الحالة، لن يتم رد أي أموال. شكرًا لك. "
					]
				},
				{
					"issueTitle": "كيف أتجنب حدوث عمليات شراء غير مقصودة؟",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Punball هي لعبة يتم تنزيلها مجانًا في جميع أنحاء العالم، ولكن الحزم والجواهر متاحة للشراء من المتجر داخل اللعبة. إذا أردت تجنب عمليات الشراء غير المقصودة، يرجى تغيير إعدادات جهازك.أجهزة iOS:",
						" اذهب إلى إعدادات > عام > القيود. ",
						" حدِّد تمكين القيود. ",
						" أدخل كلمة سر. من المحبذ إنشاء كلمة سر مختلفة عن كلمة سر إلغاء قفل جهازك. ",
						" بمجرد أن تقوم بتمكين القيود، سوف ترى قائمة من الإجراءات التي يُسمح لتطبيقاتك بتنفيذها. لمنع عمليات الشراء داخل التطبيق، يرجى إيقاف تبديل الخيار المقابل. ",
						"إذا كنت لا تريد منع عمليات الشراء داخل التطبيق بالكامل، يمكنك تغيير إعدادات كلمة السر الخاصة بتطبيقاتك. بمجرد أن تقوم بتعطيل القيود، اذهب إلى إعدادات > متجر التطبيقات > إعدادات كلمة السر.",
						"إصدار iOS 12.3 وأعلى:",
						" اذهب إلى إعدادات > وقت الشاشة > قيود المحتوى والخصوصية. ",
						" قم بتشغيل قيود المحتوى والخصوصية. ",
						" انقر فوق عمليات شراء iTunes ومتجر التطبيقات. ",
						" انقر فوق المطالبة دائمًا، أو انقر فوق عمليات الشراء داخل التطبيق > غير مسموح. ",
						"أجهزة Android:",
						" افتح Play Store. ",
						" اعثر على الإعدادات. ",
						" انقر فوق مطالبة المصادقة على عمليات الشراء. ",
						" حدِّد خيارًا غير \"أبدًا\".",
						"باتت جميع عمليات الشراء داخل التطبيق التي تتم عبر Google Play تتطلب المصادقة الآن",
						"ملاحظة: إذا قمت بتحديد “كل 30 دقيقة”، ففي كل مرة تُدخل كلمة السر وتُكمل أي معاملة، لن يكون إدخال كلمة السر شرطًا لجميع عمليات الشراء التي تتم خلال الـ 30 دقيقة التالية"
					]
				}
			]
		},
		{
			"title": "مشكلات الشبكة",
			"list": [
				{
					"issueTitle": " خطأ في الطاقة",
					"issueAnswerType": 1,
					"issueAnswer": [
						"يرجى التحقق من التالي:",
						" تحقق من شبكتك ومما إذا كان جهازك متصلًا. ",
						" تحقق مما إذا كنت تقوم بتشغيل اللعبة باستخدام أجهزة متعددة واستعملت الطاقة وأنت تستخدم الأجهزة.",
						" تحقق مما إذا كنت قد غيّرت التوقيت المحلي في جهازك. نحن لا نتعامل مع أي مشكلات طاقة ناجمة عن تغيير الوقت.",
						"إذا استمر ظهور الخطأ، يرجى التواصل معنا وإعلامنا بما يلي:",
						" القيم المُحددة لطاقتك الفعلية وتقلبات الطاقة ",
						" وتحديدًا، متى حدثت تقلبات الطاقة؟ ",
						" معرّف اللاعب الخاص بك ",
						"سوف نقدم لك بعض التعويضات بعد التحقق من مشكلتك. وسوف يستغرق الأمر من يومين إلى 3 أيام عمل تقريبًا. يرجى التحلّي بالصبر وعدم إرسال رسائل متكررة. شكرًا لك على تعاونك معنا."
					]
				},
				{
					"issueTitle": " مشكلات في الإعلانات",
					"issueAnswerType": 1,
					"issueAnswer": [
						"تتعذر مشاهدة الإعلانات:",
						"يرجى التحقق من التالي:",
						"	تأكد من أن شبكة جهازك الجوال تعمل بشكل طبيعي. يمكنك محاولة التبديل إلى G/wifi",
						" لأن تحميل أي إعلان يستغرق وقتًا، يرجى عدم النقر على أي إعلان مباشرة بعد دخول اللعبة. ويرجى لعب اللعبة لبعض الوقت حين يكون اتصال الشبكة جيدًا، ثم مشاهدة الإعلان مجددًا ",
						"إذا لم يساعد ما سبق في إصلاح المشكلة، يرجى التواصل معنا وإعلامنا بمعرّف اللاعب الخاص بك ومكان الإعلان الذي فشل في التحميل ",
						"تطبيقات غير ملائمة:",
						"أما فيما يتعلق بأي إعلان غير لائق (مثل الإعلانات العنيفة وإعلانات المقامرة) في اللعبة، يرجى إرسال رسالة إلكترونية إلى punball@habby.com متضمنة المعلومات أدناه:",
						" قم بإرفاق لقطة شاشة للمحتوى غير اللائق في الإعلان أو مقطع فيديو للإعلان.",
						" لقطة شاشة أو تسجيل شاشة لصفحة الجهة الراعية التي أعاد الإعلان توجيهك إليها",
						" معرّف اللاعب الخاص بك. بعد التحقق، سوف نقوم بمكافأتك ببعض الجواهر"
					]
				}
			]
		},
		{
			title: "اتصل بنا"
		}
	],
	"feedSubmit": {
		"isArabic": true,
		"contactUS": "تواصل معنا",
		"contentPlaceholder": "يُرجى وصف المشكلة",
		"emailPlaceholder": "يُرجى ترك بريدك الإلكتروني لكي نتمكن من التواصل معك",
		"submit": "إرسال",
		"success": "تم إرسال رسالتك بنجاح.",
		"success2": "شكرًا لك على تعليقك.",
		"returnToGame": "العودة إلى اللعبة"
	}
}