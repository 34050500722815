"use strict";

// 法语
export default {
	"backGame": "Rückkehr",
	"title": "Sélectionnez Problème",
	"issues": [
		{
			"title": "Problèmes de sauvegarde",
			"list": [
				{
					"issueTitle": "Comment sauvegarder la progression du jeu ?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Vous pouvez accéder à Punball avec un compte Game Center ou Play Games.",
						"<b>Connectez-vous en tant que visiteur </b>：Vous accéderez au jeu en tant que visiteur si c'est votre première partie et que vous ne vous êtes pas connecté à votre compte Game Center (iOS) ou Play Games (Android). Les données sauvegardées de votre compte visiteur sont associées à votre téléphone, de sorte que vous ne perdrez pas votre sauvegarde après avoir supprimé et retéléchargé le jeu. Mais les données sauvegardées de votre compte visiteur ne peuvent pas être transférées vers d'autres appareils. Veuillez noter que vous risquez de perdre vos données sauvegardées lors d'une réinitialisation d'usine, d'un flashing, d'un jailbreaking de votre téléphone, etc. Nous vous suggérons de vous connecter à votre compte Game Center (iOS) ou Play Games (Android) pour éviter de perdre la sauvegarde de votre compte visiteur.",
						"<b>Connexion au compte</b>：Si vous vous connectez à votre compte Game Center (iOS) ou Play Games (Android) avant votre première partie, vous accèderez au jeu avec votre compte par défaut. Votre progression sera automatiquement enregistrée sur votre compte Game Center (iOS) ou Play Games (Android)."
					],
				}, 
				{
					"issueTitle": "Je veux transférer ma sauvegarde vers d'autres appareils.",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Vous pouvez transférer vos sauvegardes vers d'autres appareils via votre compte Game Center (iOS) ou Play Games (Android). Mais à l'heure actuelle, les données sauvegardées ne peuvent être transférées que vers des appareils ayant le même système d'exploitation. Par exemple, iOS vers iOS, Android vers Android.",
						"Méthode : Assurez-vous d'être connecté à votre compte Game Center (iOS) ou Play Games (Android) sur votre ancien appareil. Connectez-vous au même compte Game Center (iOS) ou Play Games (Android) sur un nouvel appareil et téléchargez le jeu. Lorsque vous lancez le jeu, il détectera votre compte et récupérera les données sauvegardées. (Les utilisateurs d'Android doivent exécuter le jeu dans Play Games.)"
					]
				},
				{
					"issueTitle": "Je veux commencer une nouvelle partie.",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>Je veux conserver ma sauvegarde actuelle </b>：Vérifiez que vous êtes connecté à votre compte Game Center (iOS) ou Play Games (Android). Si oui, les données sauvegardées associées à votre compte sont automatiquement sauvegardées sur notre serveur. Maintenant, déconnectez-vous de votre compte, supprimez et retéléchargez le jeu. Ensuite, connectez-vous à un nouveau compte (qui n'a jamais été utilisé pour accéder à Punball) pour créer une nouvelle sauvegarde. Pour jouer sur l'ancien compte, revenez à votre ancien compte Game Center (iOS) ou Play Games (Android), téléchargez de nouveau le jeu et lancez-le. Le système détectera et chargera votre sauvegarde précédente.",
						"<b>Je ne veux plus de ma sauvegarde</b>： Accédez à « Contactez-nous » dans les paramètres du jeu pour nous indiquer que vous souhaitez supprimer votre sauvegarde et commencer une nouvelle partie. Nous supprimerons votre sauvegarde dans un délai de 1 à 2 jours ouvrables après réception de votre message. Les données sauvegardées ne peuvent pas être récupérées une fois effacées, alors réfléchissez bien. Il n'est pas conseillé de faire effacer votre sauvegarde, car vous vous améliorerez au fur et à mesure que vous jouerez plus. Ce n'est pas une bonne idée de commencer une nouvelle partie."
					]
				},
				{
					"issueTitle": "J'ai perdu ma progression.",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"Nous bannissons les comptes des tricheurs et supprimons leurs sauvegardes quotidiennement. Nous sommes désolés si votre sauvegarde a été supprimée. Nous espérons que tout le monde télécharge le jeu par le biais d'un canal approprié et ne triche pas pour que chacun puisse profiter d'une bonne expérience de jeu.",
						"<b>Je n'ai pas triché, mais j'ai perdu ma progression </b>：",
						"<b>Compte visiteur </b>：Vous vous êtes peut-être connecté à un compte Game Center (iOS) ou Play Games (Android) associé à une autre sauvegarde. Pour récupérer la sauvegarde de votre compte visiteur, déconnectez-vous de votre compte, supprimez et téléchargez de nouveau le jeu. (Nous vous suggérons de vous connecter à un nouveau compte pour éviter de perdre votre sauvegarde.)",
						"<b>Je me suis connecté à mon compte, mais ma sauvegarde a été perdue</b>：Vérifiez si vous avez changé de compte. Si vous êtes passé à un autre compte Game Center (iOS) ou Play Games (Android), revenez au compte précédent en accédant aux paramètres de votre téléphone.",
						"<b>Si vous ne pouvez pas récupérer votre compte</b>：N'hésitez pas à nous contacter à tout moment en nous indiquant :",
						"1. L'ID utilisateur de votre compte précédent",
						"2. Votre compte actuel",
						"3. Votre adresse e-mail",
						"4. Ce qui s'est passé avant que vous ne perdiez votre compte",
						"Si vous avez effectué des achats dans l'application sur votre compte précédent et que vous ne pouvez pas les récupérer, nous pouvons transférer tous les articles que vous avez achetés sur votre compte précédent vers le nouveau compte. Vous devrez nous fournir les informations ci-dessous :",
						"1. L'ID utilisateur de votre nouveau compte",
						"2. Ce que vous avez acheté et la quantité",
						"3. Toutes les captures d'écran ou les ID de commande de vos transactions (Nous n'acceptons que les factures Apple ou Google. Veuillez ne pas fournir d'autres informations de paiement telles que les informations relatives à vos cartes de paiement ou aux textes de paiement.)Envoyez les informations ci-dessus à notre e-mail : punball@habby.com. Après vérification, nous vous renverrons tous les articles que vous avez achetés sur votre nouveau compte."
					]
				},
				{
					"issueTitle": "Puis-je jouer sur plusieurs appareils ?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>Vous pouvez, mais ce n'est pas conseillé</b>：Pour synchroniser votre progression de jeu entre plusieurs appareils, connectez-vous simplement au même compte Game Center (iOS) ou Google Play (Android) sur vos appareils. Mais nous déconseillons de le faire. Lorsque vos appareils partagent la même sauvegarde, si vous ouvrez un coffre gratuit sur l'appareil A et que vous l'ouvrez à nouveau sur l'appareil B, vos gemmes seront déduites car vous les avez déjà ouvertes sur l'appareil A. Pour éviter de perdre des gemmes, nous vous recommandons de ne pas jouer simultanément sur plusieurs appareils."
					]
				}
			]
		}, 
		{
			"title": "Problèmes d'achat",
			"list": [
				{
					"issueTitle": " Pourquoi n'ai-je pas reçu l'objet que j'ai acheté ?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Veuillez vérifier votre connexion réseau. Quittez le jeu et recommencez plus tard.",
						"Si vous êtes sûr que vous n'avez pas reçu l'article, veuillez nous contacter et nous fournir les informations suivantes :",
						"1.<b>Votre ID de joueur</b>(dans les paramètres de jeu)",
						"2.<b>Ce que vous avez acheté et la quantité</b>",
						"3.<b>Captures d'écran ou ID de commande de votre transaction</b> (Nous n'acceptons que les factures Apple ou Google. Veuillez ne pas fournir d'autres informations de paiement telles que les informations relatives à vos cartes de paiement ou aux textes de paiement.)Veuillez envoyer les informations ci-dessus à notre e-mail : punball@habby.com.Nous vous enverrons un pack après réception et vérification de vos informations. Cela prendra environ 2-3 jours ouvrables. Soyez patient et ne nous envoyez pas de messages à répétition. Merci de votre coopération."
					]
				},
				{
					"issueTitle": "Erreur dans le nombre de gemmes et de pièces",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Si vous remarquez une erreur dans le nombre de pièces ou de gemmes, veuillez d'abord redémarrer l'application et vérifier votre connexion. Vérifiez si vous vous êtes connecté au jeu sur plusieurs appareils et si vous avez utilisé des pièces ou des gemmes sur ces appareils.",
						"Si vous êtes sûr qu'il y a une erreur dans le nombre de pièces ou de gemmes, veuillez nous contacter et nous fournir les informations suivantes :",
						"1. Votre numéro de joueur et le nombre de pièces ou de gemmes que vous avez perdues.",
						"2. Envoyez une capture d'écran ou un enregistrement d'écran à punball@habby.com. Cela nous aidera à cerner le problème et à vous dédommager plus rapidement.",
						"3. Dites-nous ce que vous avez fait avant de perdre les objets (réinstallation, mise à jour ou exécution du jeu sur de nouveaux appareils).",
					]
				},
				{
					"issueTitle": "Je veux être remboursé.",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Vous trouverez ci-dessous notre politique de remboursement. Un remboursement ne sera effectué que si les conditions ci-dessous sont remplies :",
						"1. Une demande de remboursement n'est acceptée que lorsque les gemmes, pièces de monnaie ou tout autre objet en jeu que vous avez acheté sont toujours sur votre compte et n'ont pas été utilisés du tout.",
						"2. Si vous avez déjà reçu un remboursement pour un article acheté, les autres achats que vous avez effectués ne seront pas remboursés.",
						"3. Un remboursement ne peut vous être accordé que si vous soumettez une demande de remboursement dans les 7 jours suivant votre achat. Toute demande de remboursement soumise après 7 jours sera rejetée.",
						"4.Si vous remplissez nos conditions de remboursement, veuillez nous contacter et nous fournir une capture d'écran ou le numéro de commande de votre transaction, et votre numéro de joueur dans le jeu. Notre adresse e-mail est : punball@habby.com Nous examinerons votre demande de remboursement dès réception de votre e-mail, et vous rembourserons une fois qu'elle aura été approuvée. Cela prendra environ 2-3 jours ouvrables. Soyez patient et ne nous envoyez pas de messages à répétition. Merci de votre coopération.Remarque : Si votre compte ou votre appareil a été utilisé par d'autres personnes, ou si vous avez permis à d'autres personnes d'effectuer des achats sur votre compte, nous ne sommes pas responsables des achats effectués sur votre compte. Dans ce cas, aucun remboursement ne sera effectué."
					]
				},
				{
					"issueTitle": "Comment éviter les achats accidentels ?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Punball est un jeu que vous pouvez télécharger gratuitement dans le monde entier, mais vous pouvez acheter des packs et des gemmes dans la boutique du jeu. Si vous souhaitez éviter des achats accidentels, veuillez modifier les paramètres de votre appareil.Appareils iOS :",
						"1. Accédez à Paramètres > Général > Restrictions.",
						"2. Sélectionnez Activer les restrictions.",
						"3. Entrez un code d'accès. Il est recommandé de définir un code d'accès différent de celui utilisé pour déverrouiller votre appareil.",
						"4. Une fois les Restrictions activées, vous verrez une liste d'actions que vos applications sont autorisées à effectuer. Pour éviter les achats dans l'application, veuillez désactiver l'option correspondante.",
						"Si vous ne voulez pas empêcher complètement les achats dans l'application, vous pouvez modifier les paramètres de mot de passe de vos applications. Une fois les restrictions désactivées, accédez à Paramètres > App Store > Paramètres du mot de passe.",
						"iOS 12.3 et supérieur :",
						"1. Accédez à Paramètres > Durée d'affichage > Contenu et restrictions de confidentialité.",
						"2. Activer les restrictions de contenu et de confidentialité.",
						"3. Appuyez sur iTunes et Achats App Store.",
						"4. Appuyez sur Toujours requis, ou appuyez sur Achats intégrés > Ne pas autoriser.",
						"Appareils Android",
						"1. Ouvrez le Play Store.",
						"2. Rechercher les paramètres.",
						"3. Appuyez sur Exiger une authentification pour les achats.",
						"4. Sélectionnez l’une des options, excepté Jamais.",
						"Tous les achats dans l'application via Google Play nécessitent désormais une authentification.",
						"Remarque : Si vous sélectionnez « Toutes les 30 minutes », chaque fois que vous saisissez le mot de passe et effectuez une transaction, aucun mot de passe n'est requis pour tous les achats effectués dans les 30 minutes qui suivent."
					]
				}
			]
		},
		{
			"title": "Problèmes de réseau",
			"list": [
				{
					"issueTitle": "Erreur d'énergie",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Veuillez vérifier ce qui suit :",
						"1. Vérifiez votre réseau et si votre appareil est connecté.",
						"2. Vérifiez si vous exécutez le jeu sur plusieurs appareils et si vous avez utilisé de l'énergie sur ces appareils.",
						"3. Vérifiez si vous avez modifié l'heure locale de votre appareil. Nous ne nous occupons d'aucun problème d'énergie causé par un changement d'heure.",
						"Si l'erreur persiste, veuillez nous contacter et nous indiquer :",
						"1. Les valeurs spécifiques de votre énergie actuelle et de vos fluctuations d'énergie.",
						"2. Plus précisément, quand les fluctuations d'énergie se sont-elles produites ?",
						"3. Votre ID de joueur.",
						"Nous vous offrirons une compensation après vérification de votre problème. Cela prendra environ 2-3 jours ouvrables. Soyez patient et ne nous envoyez pas de messages à répétition. Merci de votre coopération."
					]
				},
				{
					"issueTitle": "Problèmes avec les publicités",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Impossible de regarder les publicités :",
						"Veuillez vérifier ce qui suit :",
						"1. Vérifiez que le réseau de votre téléphone est normal. Vous pouvez essayer de basculer entre 4G et Wi-Fi.",
						"2. Comme il faut du temps pour charger une publicité, veuillez ne pas appuyer sur une publicité immédiatement après avoir accédé au jeu. Jouez un peu pendant que la connexion réseau est bonne, puis essayez de regarder de nouveau la publicité.",
						"Si cela ne résout pas le problème, veuillez nous contacter et nous indiquer votre ID de joueur et où se trouve la publicité qui ne s'est pas chargée.",
						"Applications inappropriées :",
						"Pour toute publicité inappropriée (p. ex. violence ou jeu de hasard) dans le jeu, veuillez nous écrire à punball@habby.com en indiquant les informations ci-dessous :",
						"1. Joignez une capture d'écran du contenu inapproprié de la publicité ou une vidéo de la publicité.",
						"2. Une capture d'écran ou un enregistrement d'écran de la page du sponsor vers laquelle la publicité vous redirige.",
						"3. Votre ID de joueur. Après vérification, nous vous récompenserons avec des gemmes."
					]
				}
			]
		},
		{
			title: "Nous contacter"
		}
	],
	"feedSubmit": {
		"contactUS": "Contactez-nous",
		"contentPlaceholder": "Veuillez décrire votre problème",
		"emailPlaceholder": "Veuillez laisser votre e-mail afin que nous puissions vous contacter :",
		"submit": "Envoyer",
		"success": "Votre message a bien été envoyé. Merci pour vos commentaires.",
		"returnToGame": "Retour au jeu"
	}
}