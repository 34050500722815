"use strict";

// 葡萄牙
export default {
	"backGame": "Retorno",
	"title": "Pregunta de elección",
	"issues": [
		{
			"title": "Problemas de salvamento",
			"list": [
				{
					"issueTitle": " Como salvo o progresso do jogo?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Você pode entrar no Punball com uma conta Game Center ou Play Games.",
						"<b>Conectando como visitante</b>：Você entrará no jogo como visitante se for o seu primeiro jogo e você não estiver conectado em sua conta Game Center (iOS) ou Play Games (Android). Os dados salvos pela sua conta de visitante estão associados ao seu telefone, então você não perderá nada se apagar e baixar o jogo novamente. Mas os dados salvos pela sua conta de visitante não podem ser transferidos para outros dispositivos. Saiba que você pode perder seus dados salvos se fizer uma redefinição de fábrica, flashing ou jailbreaking do telefone, etc. Sugerimos conectar à sua conta do Game Center (iOS) ou Play Games (Android) para evitar perder os dados da sua conta de visitante.",
						"<b>Conectando com uma conta</b>：Se você se conectar com a conta do Game Center (iOS) ou Play Games (Android) antes do primeiro jogo, entrará o jogo com a sua conta. O progresso do seu jogo será salvo automaticamente na sua conta Game Center (iOS) ou Play Games (Android)."
					],
				}, 
				{
					"issueTitle": "Quero transferir meus dados salvos para outros dispositivos.",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Você pode transferir seus dados salvos para outros dispositivos através da sua conta Game Center (iOS) ou Play Games (Android). Mas no momento, os dados salvos só podem ser transferidos para dispositivos com o mesmo sistema operacional. Por exemplo, iOS para iOS e Android para Android.",
						"Método: Certifique-se de ter conectado à sua conta Game Center (iOS) ou Play Games (Android) em seu dispositivo antigo. Conecte-se com a mesma conta Game Center (iOS) ou Play Games (Android) em um novo dispositivo e baixe o jogo. Quando você executar o jogo, ele detectará sua conta e recuperará os dados salvos. (usuários do Android precisam executar o jogo no Play Games)"
					]
				},
				{
					"issueTitle": "Quero iniciar um novo jogo:",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>Quero manter meu jogo salvo atual</b>：Certifique-se de ter conectado à sua conta Game Center (iOS) ou Play Games (Android). Se já conectou, os dados salvos associados com a sua conta são salvos em nosso servidor automaticamente. Agora, desconecte da sua conta, exclua e baixe o jogo de novo. Então, conecte-se a uma nova conta (que nunca foi usada com o Punball) para criar um novo jogo salvo. Para jogar na conta antiga, volte para a sua conta Game Center (iOS) ou Play Games (Android) anterior, baixe e execute o jogo de novo. O sistema vai detectar e carregar seu jogo salvo anterior.",
						"<b>Não quero mais o meu jogo salvo</b>：Vá para “Fale conosco” nas configurações do jogo para nos informar que você deseja excluir seu jogo salvo e iniciar um novo jogo. Vamos excluir seu jogo salvo em 1-2 dias úteis após receber a mensagem. Os dados salvos não podem ser recuperados depois de excluídos, então pense bem. Não recomendamos excluir seu jogo salvo, já que você vai melhorar no jogo quanto mais jogar. Não é uma boa ideia começar um jogo novo."
					]
				},
				{
					"issueTitle": "Perdi todo o meu progresso.",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"Banimos contas de trapaceiros e excluímos seus jogos salvos todos os dias. Sentimos muito se o seu jogo salvo foi excluído. Esperamos que todo mundo baixe o jogo pelo canal correto e não use cheats para garantir que todos tenham uma boa experiência de jogo.",
						"<b>Não fiz trapaça, mas perdi todo o meu progresso</b>：",
						"<b>Conta de visitante</b>：Certifique-se de ter conectado à sua conta Game Center (iOS) ou Play Games (Android) associada com outro jogo salvo. Para voltar para o jogo salvo da sua conta de visitante, desconecte da sua conta, exclua e baixe o jogo de novo. (sugerimos conectar-se a uma nova conta para evitar perder seu jogo salvo)",
						"<b>Me conectei à minha conta, mas o jogo salvo foi perdido</b>：Verifique se você trocou de conta. Se você trocou para outra conta Game Center (iOS) ou Play Games (Android), troque para a conta anterior nas configurações do seu telefone.",
						"<b>Se você não conseguir recuperar a sua conta</b>：Entre em contato conosco e forneça:",
						"1. A ID de usuário da sua conta anterior",
						"2. Sua conta atual",
						"3. Seu endereço de e-mail",
						"4. O que aconteceu antes de você perder sua conta",
						"Se você fez compras dentro do app com a sua conta anterior e não consegue recuperar ela, podemos transferir todos os itens que você comprou em uma conta anterior para a nova. Você precisa nos fornecer as informações a seguir:",
						"1. A ID de usuário da sua conta nova",
						"2. O que você comprou e as quantidades",
						"3. Capturas de tela ou IDs de pedidos de todas as suas transações (só aceitamos contas da Apple ou Google. Não forneça outras informações de pagamento, como dos seus cartões ou mensagens de confirmação)Envie as informações acima para o e-mail: punball@habby.com. Depois de verificar, devolveremos todos os itens que você comprou para a sua conta nova."
					]
				},
				{
					"issueTitle": "Posso jogar em vários dispositivos?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>Você pode, mas não é recomendável</b>：Para sincronizar o progresso do jogo entre vários dispositivos, faça login na mesma conta do Game Center (iOS) ou do Google Play (Android) em seus dispositivos. Mas não recomendamos fazer isso. Quando seus dispositivos compartilham o mesmo jogo salvo, se você abrir o baú grátis no dispositivo A e depois no dispositivo B, suas gemas serão deduzidas porque você já abriu o baú no dispositivo A. Para evitar a perda acidental de gemas, não recomendamos jogar o jogo em vários dispositivos ao mesmo tempo."
					]
				}
			]
		}, 
		{
			"title": "Problemas de compra",
			"list": [
				{
					"issueTitle": "Por que não recebi o item que comprei?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Verifique a sua conexão de rede. Saia do jogo e reinicie depois.",
						"Se tiver certeza de que não recebeu o item, entre em contato conosco e forneça as seguintes informações:",
						"1.<b>Sua ID do jogador</b>(nas configurações do jogo)",
						"2.<b> O que você comprou e as quantidades</b>",
						"3.<b>Capturas de tela ou IDs das suas transações</b>(só aceitamos contas da Apple ou Google. Não forneça outras informações de pagamento, como dos seus cartões ou mensagens de confirmação)Envie as informações acima para o e-mail: punball@habby.com.Enviaremos seu pacote depois de receber e verificar seus dados. Demora entre 2 e 3 dias úteis. Tenha paciência e não envie mensagens repetidas. Agradecemos a sua cooperação."
					]
				},
				{
					"issueTitle": "Erro no número de gemas e moedas",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Se você perceber um erro no número de moedas ou gemas, reinicie o aplicativo e verifique sua conexão. Verifique se você fez login no jogo em vários dispositivos e se usou moedas ou gemas nesses dispositivos.",
						"Se tiver certeza de que há um erro no número de moedas ou gemas, entre em contato conosco e forneça as seguintes informações:",
						"1. Sua ID de jogador e o número de moedas ou gemas que você perdeu.",
						"2. Envie qualquer captura ou gravação de tela para punball@habby.com. Isso nos ajudará a entender o problema e oferecer uma compensação mais rapidamente.",
						"3. Conte-nos o que você estava fazendo antes de perder os itens (reinstalando, atualizando ou executando o jogo em novos dispositivos).",
					]
				},
				{
					"issueTitle": "Quero receber um reembolso.",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Nossa política de reembolso está abaixo. Reembolsos só são concedidos caso as condições abaixo sejam cumpridas:",
						"1. Um pedido de reembolso é aceito apenas quando as gemas, moedas ou qualquer outro item do jogo que você comprou ainda esteja em sua conta e não tenha sido usado.",
						"2. Se você já recebeu reembolso por um item comprado, nenhuma outra compra que você fizer será reembolsada.",
						"3. Você pode receber um reembolso somente se enviar um pedido de reembolso até 7 dias após a compra. Qualquer pedido de reembolso enviado depois de 7 dias será rejeitado.",
						"4. Se você cumprir com nossas condições de reembolso, entre em contato conosco e envie uma captura de tela ou a ID de pedido da sua transação e a sua ID do jogador no jogo. Nosso endereço de e-mail é: punball@habby.com. Verificaremos o seu pedido de reembolso ao receber seu e-mail e o reembolso será concedido após aprovação. Demora entre 2 e 3 dias úteis. Tenha paciência e não envie mensagens repetidas. Agradecemos a sua cooperação.Aviso: Se sua conta ou dispositivo foi usado por outros, ou se você permitiu que outras pessoas façam compras com a sua conta, não nos responsabilizamos pelas contas feitas em sua conta. Nesse caso, nenhum reembolso será concedido. Obrigado."
					]
				},
				{
					"issueTitle": "Como evito compras acidentais?",
					"issueAnswerType": 1,
					"issueAnswer": [
						"O Punball é um jogo grátis de baixar no mundo inteiro, mas pacotes e gemas podem ser comprados dentro do jogo. Se você deseja evitar compras acidentais, altere as configurações do seu dispositivo.Dispositivos iOS:",
						"1. Vá para Ajustes > Geral > Restrições.",
						"2. Selecione Ativar Restrições.",
						"3. Insira uma senha. Recomenda-se definir uma senha diferente da usada para desbloquear o dispositivo.",
						"4. Depois de ativar as restrições, você verá uma lista de ações que seus apps podem executar. Para impedir compras dentro do app, desative a opção correspondente.",
						"Se você não quiser impedir totalmente as compras dentro do app, poderá alterar as configurações de senha dos seus aplicativos. Depois de desativar as restrições, vá para Ajustes > App Store > Ajustes de senha.",
						"iOS 12.3 ou superior:",
						"1. Vá para Ajustes > Tempo de tela > Restrições de conteúdo e privacidade.",
						"2. Ative as Restrições de Conteúdo e Privacidade.",
						"3. Toque em Compras no iTunes e App Store.",
						"4. Toque em Sempre Exigir, ou em Compras no Aplicativo > Não permitir.",
						"Dispositivos Android:",
						"1. Abra a Play Store.",
						"2. Encontre Configurações.",
						"3. Toque em Exigir autenticação para compras.",
						"4. Selecione uma opção, exceto Nunca.",
						"Todas as compras dentro do app através do Google Play agora exigem autenticação.",
						"Aviso: Se você selecionar “A cada 30 minutos”, toda vez que digitar sua senha e concluir uma transação, nenhuma senha será exigida para todas as compras feitas nos próximos 30 minutos."
					]
				}
			]
		},
		{
			"title": "Problemas de rede",
			"list": [
				{
					"issueTitle": "Erro de energia",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Verifique as coisas a seguir:",
						"1. Verifique sua rede e se o dispositivo está conectado.",
						"2. Verifique se você está executando o jogo em vários dispositivos e usou Energia nesses dispositivos.",
						"3. Verifique se você alterou o horário local do seu dispositivo. Não lidamos com nenhum problema de energia causado por alteração de horário.",
						"Se o erro persistir, entre em contato e nos informe:",
						"1. Os valores específicos da sua Energia e as variações de Energia",
						"2. Especificamente, quando ocorreram as variações de Energia?",
						"3. Sua ID de jogador.",
						"Ofereceremos algum tipo de indenização depois de verificar o problema. Demora entre 2 e 3 dias úteis. Tenha paciência e não envie mensagens repetidas. Agradecemos a sua cooperação."
					]
				},
				{
					"issueTitle": "Problema com anúncios",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Não é possível assistir anúncios:",
						"Verifique as coisas a seguir:",
						"1. Certifique-se de que a rede do seu telefone está normal. Você pode tentar alternar entre 4G/WiFi.",
						"2. Como demora para carregar um anúncios, não toque em anúncios assim que entrar no jogo. Jogue o jogo um pouco e assista o anúncio quando a conexão de rede estiver boa.",
						"Se isso não resolver o problema, entre em contato e informe o ID do jogador e o local onde o anúncio não foi carregado.",
						"Apps impróprios：",
						"Para qualquer anúncio impróprio (por exemplo, violência ou jogos de azar) no jogo, envie um e-mail para punball@habby.com com as informações abaixo:",
						"1. Anexe uma captura de tela do conteúdo impróprio no anúncio ou um vídeo do anúncio.",
						"2. Uma captura de tela ou gravação de tela da página do patrocinador para a qual o anúncio foi redirecionado.",
						"3. Sua ID de jogador. Após a verificação, vamos recompensá-lo com algumas gemas."
					]
				}
			]
		},
		{
			title: "Entre em contato conosco"
		}
	],
	"feedSubmit": {
		"contactUS": "Fale conosco",
		"contentPlaceholder": "Descreva seu problema",
		"emailPlaceholder": "Deixe seu e-mail para que possamos entrar em contato com você:",
		"submit": "Enviar",
		"success": "Sua mensagem foi enviada com sucesso. Agradecemos por seu feedback.",
		"returnToGame": "Voltar ao jogo"
	}
}