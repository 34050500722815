"use strict";

// 日语
export default {
	"backGame": "戻る ",
	"title": "問題を選択",
	"issues": [
		{
			"title": "保存の問題",
			"list": [
				{
					"issueTitle": "ゲームの進行状況を保存するには？",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Game Center アカウントまたは Play Games アカウントで Punball に参加できます。",
						"<b>ゲストとしてログイン</b>：ゲームをプレイするのが初めての場合や、Game Center（iOS）または Play Games（Android）アカウントにログインしなかった場合はゲストとして参加します。ゲストアカウントのセーブデータは電話に関連付けられているので、ゲームを削除して再度ダウンロードするとセーブデータを失います。ゲストアカウントのセーブデータを別のデバイスに転送することはできません。ファクトリーリセットやフラッシング、ジェイルブレイクなどを行うとセーブデータを失う可能性がありますのでご注意ください。ゲストアカウントのセーブデータが失われないようにするには、Game Center（iOS）アカウントまたは Play Games（Android）アカウントにログインすることをお勧めします。",
						"<b>アカウントへのログイン</b>：初めてゲームをプレイする前に Game Center（iOS）または Play Games（Android）アカウントにログインした場合は、既定で自分のアカウントでゲームに参加します。ゲームの進行状況は自動的に Game Center（iOS）または Play Games（Android）アカウントに保存されます。"
					],
				}, 
				{
					"issueTitle": "セーブデータを他のデバイスに転送したい",
					"issueAnswerType": 1,
					"issueAnswer": [
						"セーブデータを Game Center（iOS）または Play Games（Android）アカウントを通じて別のデバイスに転送できます。ただし、現時点ではセーブデータは同じ OS のデバイスにのみ転送できます。たとえば、iOS なら iOS、Android なら Android にのみ転送できます。",
						"方法：古いデバイスで Game Center（iOS）アカウントまたは Play Games（Android）アカウントにログインしていることを確認します。新しいデバイスで同じ Game Center（iOS）アカウントまたは Play Games（Android）アカウントにログインして、ゲームをダウンロードします。ゲームを実行すると、アカウントを検出してセーブデータを取得します（Android ユーザーは Play Games でゲームを実行する必要があります）。"
					]
				},
				{
					"issueTitle": "新しいゲームを始めたい",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>現在のセーブデータを取っておきたい</b>：Game Center（iOS）または Play Games（Android）アカウントにログインしていることを確認します。アカウントに関連付けられているセーブデータがある場合は、自動的にサーバーに保存されます。アカウントからログアウトし、ゲームを削除して再度ダウンロードします。新しいアカウント(Punball への参加には一度も使用していないアカウント)にログインして新しいセーブデータを作成します。古いアカウントでプレイするには、以前の Game Center（iOS）アカウントまたは Play Games（Android）アカウントに切り替えて、ゲームを再度ダウンロードして実行してください。前回のセーブデータが検出され、読み込まれます。",
						"<b>セーブデータはもういらない</b>：ゲーム設定の [Contact Us] に移動して、セーブデータを削除して新しいゲームを始めたい旨をお知らせください。メッセージを受け取ってから 1-2 営業日以内にセーブデータを削除します。セーブデータは一度削除すると取り戻すことはできないので、よくお考えください。ゲームはプレイするほどうまくなるので、セーブデータが削除されるのは望ましくありません。新しいゲームを始めるのは好ましくありません。"
					]
				},
				{
					"issueTitle": "進行状況を失った。",	
					"issueAnswerType": 1,
					"issueAnswer": [
						"毎日チートをしたユーザーのアカウントを停止し、セーブデータを削除しています。セーブデータが削除されてしまった場合は申し訳ございません。自分も他のユーザーもゲームを楽しめるように、誰もがゲームを適切な経路からダウンロードし、チートはしないことを願っています。",
						"<b>チートはしていないが進行状況が消えた</b>：",
						"<b>ゲストアカウント</b>：別のセーブデータに関連付けられている Game Center（iOS）アカウントまたは Play Games（Android）アカウントにログインしている可能性があります。ゲストアカウントのセーブデータを取り戻すには、アカウントからログアウトしてゲームを削除し、再度ダウンロードしてください（新しいアカウントにログインしてセーブデータを失わないようにすることをお勧めします）。",
						"<b>アカウントにログインしたけどセーブデータが消えていた</b>：アカウントを切り替えたかどうかを確認してください。別の Game Center（iOS）アカウントまたは Play Games（Android）アカウントに切り替えた場合は、電話の設定にアクセスして以前のアカウントに戻してください。",
						"<b>アカウントにアクセスできない場合</b>：いつでもお気軽にお問い合わせください。その際、次の情報をご提供ください。",
						"1. 以前のアカウントのユーザー ID",
						"2. 現在のアカウント",
						"3. 電子メールアドレス",
						"4. アカウントを失うまでの経緯",
						"以前のアカウントでアプリ内購入を行い、アカウントにアクセスできなくなった場合は、以前のアカウントで購入したすべてのアイテムを新しいアカウントに転送できます。次の情報をご提供いただく必要があります。",
						"1. 新しいアカウントのユーザー ID",
						"2. 購入したアイテムと数量",
						"3. 取引のスクリーンショットまたは注文 ID（Apple または Google の請求書のみ受け付けます。ペイメントカードや通信文など、その他の支払情報は提供しないでください。）上記の情報を電子メール（punball@habby.com）に送信してください。確認した後、購入したすべてのアイテムを新しいアカウントに戻します。"
					]
				},
				{
					"issueTitle": "複数のデバイスでゲームをプレイできる？",
					"issueAnswerType": 1,
					"issueAnswer": [
						"<b>できますがお勧めしません。</b>：複数のデバイス間でゲームの進行状況を同期するには、デバイスで同じ Game Center（iOS）アカウントまたは Google Play（Android）アカウントにログインします。しかし、これはお勧めしません。同じセーブデータをデバイス間で共有すると、デバイス A で無料の宝箱を開き、デバイス B で再び開くと、デバイス A で既に宝箱を開いているのでジェムが減ります。誤ってジェムを失わないようにするには、複数のデバイスで同時にゲームをプレイするのを控えてください。"
					]
				}
			]
		}, 
		{
			"title": "購入の問題",
			"list": [
				{
					"issueTitle": "購入したアイテムが受け取れない。",
					"issueAnswerType": 1,
					"issueAnswer": [
						"ネットワーク接続を確認してください。ゲームを終了し、暫く待ってから再起動してください。",
						"アイテムを受け取っていないと確信している場合は、次の情報を提供してください",
						"1.<b>プレイヤー ID</b>（ゲーム設定にあります）",
						"2.<b>購入したアイテムと数量</b>",
						"3.<b>取引のスクリーンショットまたは注文 ID</b>（Apple または Google の請求書のみ受け付けます。ペイメントカードや通信文など、その他の支払情報は提供しないでください。）上記の情報を電子メール（punball@habby.com）に送信してください。情報を受け取って確認した後、パックを送信します。これには 2-3 営業日ほどかかります。同じメッセージを何度も送信しないでください。ご協力をよろしくお願いします。"
					]
				},
				{
					"issueTitle": "コインとジェムの数量が間違っている",
					"issueAnswerType": 1,
					"issueAnswer": [
						"あなたのコインやジェムの数に誤りがあることに気付いた場合は、まずアプリを再起動して接続を確認してください。複数のデバイスでゲームにログインしたかどうか、それらのデバイスでコインやジャムを使用したかどうかを確認してください。",
						"コインやジェムの数に間違いがあると確信できた場合は、以下の情報を我々に提供するようお願いします:",
						"1. あなたのプレイヤー ID と失ったコインまたはジェムの数量。",
						"2. スクリーンショットまたは画面を記録したものを punball@habby.com まで送信してください。それにより問題を突き止め、素早く補償を行うことができます。",
						"3. アイテムを失う前に行ったことを教えてください（新しいデバイスでゲームを再インストールした、更新した、実行した等）。",
					]
				},
				{
					"issueTitle": "払い戻しを受けたい。",
					"issueAnswerType": 1,
					"issueAnswer": [
						"払い戻しに関するポリシーは次の通りです。次の条件を満たす場合に限り、払い戻しが受けられます。",
						"1. 払い戻しのリクエストは、ジェム、コイン、その他の購入したゲーム内アイテムがアカウント内にあり、まったく使用されていない場合に限り受け付けます。",
						"2. 購入したアイテムの払い戻しを以前に受けたことがある場合、他の購入の払い戻しは受けられません。",
						"3. 購入から 7 日以内に払い戻しを申請した場合に限り、払い戻しを受けられます。7 日が経過した後に払い戻しを申請しても拒否されます。",
						"4. 払い戻しの条件を満たしている場合は、取引のスクリーンショットまたは注文 ID とゲーム内のプレイヤー ID をお知らせください。電子メールアドレスはpunball@habby.com です。電子メールが届いたら払い戻しの申請内容を確認し、承認され次第払い戻しいたします。これには 2-3 営業日ほどかかります。同じメッセージを何度も送信しないでください。ご協力をよろしくお願いします。注意：他のユーザーがあなたのアカウントまたはデバイスを使用していた場合、または他のユーザーがあなたのアカウントで購入するのを許可していた場合は、私たちはアカウントでの購入に対する責任を一切負いません。その場合は払い戻しは受けられません。よろしくお願いします。"
					]
				},
				{
					"issueTitle": "誤って購入してしまうのを防ぐには？",
					"issueAnswerType": 1,
					"issueAnswer": [
						"Punball は世界中どこからでも基本無料でダウンロードできるゲームですが、パックやジェムはゲーム内ショップで購入できます。誤って購入してしまわないようにするには、デバイスの設定を変更してください。iOS デバイス：",
						"1. [設定] > [一般] > [機能制限] の順に移動します",
						"2. [制限を有効にする] を選択します。",
						"3. パスコードを入力します。デバイスのロックを解除するためのパスコードとは異なるパスコードを設定することをお勧めします。",
						"4. 制限を有効にすると、アプリが実行が許可されている操作のリストが表示されます。アプリ内購入ができないようにするには、対応するオプションを無効にしてください。",
						"アプリ内購入が完全にできないようにしたくない場合は、アプリのパスワード設定を変更できます。制限を無効にしたら、[設定] > [App Store] > [パスワードの設定] の順に移動します。",
						"iOS 12.3 以降：",
						"1. [設定] > [スクリーンタイム] > [コンテンツとプライバシーの制限] の順に移動します。",
						"2. [コンテンツとプライバシーの制限] を有効にします。",
						"3. [iTunesおよびApp Storeでの購入] をタップします。", 						"4. [常に必要] をタップするか、[App内課金] > [許可しない] の順にタップします。",
						"Android デバイス：",
						"1. Play ストアを開きます。",
						"2. [設定] を見つけます。",
						"3. [購入時には認証を必要とする] をタップします。",
						"4. [認証は不要] 以外の選択肢を選択します。",
						"これで Google Play でのアプリ内購入は必ず認証が必要になります。",
						"注意：[30 分毎に] を選択した場合、パスワードを入力して取引を完了すると、それから 30 分以内に行う購入についてはパスワードは不要です。"
					]
				}
			]
		},
		{
			"title": "ネットワークの問題",
			"list": [
				{
					"issueTitle": "エネルギーエラー",
					"issueAnswerType": 1,
					"issueAnswer": [
						"次の点を確認してください。",
						"1.  ネットワークと、デバイスが接続されているかどうかを確認してください。",
						"2.  複数のデバイスでゲームを実行していて、それらのデバイスでエネルギーを使用したかどうかを確認してください。",
						"3.  デバイスのローカル時刻を変更したかどうかを確認してください。時刻の変更が原因で発生したエネルギー問題には対処しません。",
						"それでもエラーが解決しない場合は、次の情報をお知らせください。",
						"1. 実際のエネルギーの具体的な値とエネルギーの変動",
						"2. エネルギーの変動が発生した具体的な日時",
						"3. プレイヤー ID",
						"問題を確認した後、補償を提供いたします。これには 2-3 営業日ほどかかります。同じメッセージを何度も送信しないでください。ご協力をよろしくお願いします。"
					]
				},
				{
					"issueTitle": "広告の問題",
					"issueAnswerType": 1,
					"issueAnswer": [
						"広告が見れない：",
						"次の点を確認してください。",
						"1. 電話のネットワークが正常であることを確認してください。4G/WiFi に切り替えてみてください。",
						"2. 広告の読み込みには時間がかかりますので、ゲームに入った直後に広告はタップしないでください。ネットワーク接続が良好なときに少しゲームをプレイしてからもう一度広告を見るようにしてください。",
						"これで問題が解決しない場合は、Google までご連絡いただき、プレーヤー ID と読み込みに失敗した広告の場所をお知らせください。",
						"不適切なアプリ：",
						"ゲーム内で不適切な広告（暴力やギャンブルなど）が表示された場合は、次の情報を記載した電子メールを punball@habby.com に送信してください。",
						"1. 広告内の不適切なコンテンツのスクリーンショットまたは広告のビデオを添付してください。",
						"2. 広告のリダイレクト先であるスポンサーのページのスクリーンショットまたは画面を記録したもの。",
						"3. プレイヤー ID。確認後、いくつかのジェムを報酬として提供します。"
					]
				}
			]
		},
		{
			title: "お問い合わせ"
		}
	],
	"feedSubmit": {
		"contactUS": "お問い合わせ",
		"contentPlaceholder": "問題の内容を記入してください",
		"emailPlaceholder": "連絡可能なメールアドレスを記入してください：",
		"submit": "送信",
		"success": "メッセージを送信しました。ありがとうございます。",
		"returnToGame": "ゲームに戻る"
	}
}