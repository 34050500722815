"use strict";

import error from './error'

const sendBrowserError = error.sendBrowserError;

let globalUserInfo = {};

window.get_player_info = function (userInfo) {
	try {
		if (userInfo) {
			globalUserInfo = decodeURIComponent(userInfo);
			globalUserInfo = JSON.parse(globalUserInfo);
		}
	} catch (error) {
		globalUserInfo = {};
		sendBrowserError(error, 10100, userInfo);
	}
};

function goToFacebook() {
	const url = encodeURIComponent('https://www.facebook.com/Punball-1705569912922526/')
	window.open('uniwebview://goto_facebook?facebook_url=' + url + '&facebook_id=1705569912922526');
}

function sendRequest() {
	window.open('uniwebview://get_player_info');
}

function closePage() {
	setTimeout(function () {
		window.open('uniwebview://close');
	}, 300);
}

sendRequest();

export default {
	getUserInfo: function () {
		return globalUserInfo;
	},
	closePage,
	goToFacebook
}